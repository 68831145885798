/* eslint-disable no-unused-vars */
import { Button, Flexbox, FormField, Select, Toggle } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import { replaceEvpOption } from '../../../PortfolioDetails/utils';
import {
  ShowImportedDealsText,
  ToggleContainer,
  ToggleText,
} from './ContractedPlanLeFilter.styles';

interface ContractedPlanLeFiltersProps {
  setSelectedCommodity: (selectedOption: string) => void;
  selectedCommodity: string;
  commodityOptions: string[];
  selectedEvp: string;
  evpOptions: string[];
  setSelectedEvp: (selectedOption: string) => void;
  setIsImportedDealsShow: (isImportedDealsShow: boolean) => void;
  isImportedDealsShow: boolean;
  clearFilters: () => void;
}
const ContractedPlanLeFilters: React.FC<ContractedPlanLeFiltersProps> = ({
  setSelectedCommodity,
  selectedCommodity,
  commodityOptions,
  setSelectedEvp,
  evpOptions,
  selectedEvp,
  setIsImportedDealsShow,
  isImportedDealsShow,
  clearFilters,
}) => {
  const filterFields = [
    {
      id: 'commodityVal',
      label: 'Commodity',
      value: selectedCommodity,
      options: commodityOptions,
      handleOnChange: (selectedOption: string) => setSelectedCommodity(selectedOption),
    },
    {
      id: 'evp',
      label: 'EVP',
      value: selectedEvp,
      options: evpOptions,
      handleOnChange: (selectedOption: string) => setSelectedEvp(selectedOption),
    },
  ];
  return (
    <Flexbox flexDirection='column' style={{ width: '20%' }}>
      <div className='filterName'>Filters</div>
      {filterFields.map((field) => {
        const { id, label, value, options, handleOnChange } = field;
        return (
          <FormField key={id} id={id} label={label}>
            <Select
              size='medium'
              allowClear={false}
              value={value}
              aria-label={label}
              onChange={(selectedValue) => handleOnChange(selectedValue)}
            >
              {options?.length &&
                options?.map((item) => (
                  <option key={item} value={item}>
                    {replaceEvpOption(item)}
                  </option>
                ))}
            </Select>
          </FormField>
        );
      })}
      <ShowImportedDealsText>Show Deals Data</ShowImportedDealsText>
      <ToggleContainer>
        <Flexbox flexDirection='row' gap='10px'>
          <ToggleText>No</ToggleText>{' '}
          <Toggle
            data-testid='yes-no-toggle'
            checked={isImportedDealsShow}
            onClick={() => setIsImportedDealsShow(!isImportedDealsShow)}
          />{' '}
          <ToggleText>Yes</ToggleText>
        </Flexbox>
      </ToggleContainer>
      <Button
        className='getDataBtn'
        variant='outlined'
        style={{ marginTop: '10px' }}
        onClick={() => clearFilters()}
      >
        Clear Filters
      </Button>
    </Flexbox>
  );
};
export default ContractedPlanLeFilters;
