export const AUDIT_TABLE = 'AUDIT_LOG';

export const I_ALIGNE_TABLE = 'I_ALIGNE';
export const I_GROUP_TABLE = 'I_GROUP';
export const ENDUR_I_GAS_TABLE = 'ENDUR_I_GAS';
export const NUCLEUS_I_POWER_TABLE = 'NUCLEUS_I_POWER';
export const ENDUR_SEAM_REGION_SCOPE_TABLE = 'ENDUR_SEAm_REGION_SCOPE';
export const NUCLEUS_SEAM_REGION_SCOPE_TABLE = 'NUCLEUS_SEAM_REGION_SCOPE';
export const ENDUR_SLMT_NCF_CATEGORY_SCOPE_TABLE = 'ENDUR_SLMT_NCF_CATEGORY_SCOPE';
export const ENDURSLMT_STATIC_MAP_NCF_TABLE = 'ENDURSLMT_STATIC_MAP_NCF';
export const ENDURSLMT_I_POWER_TABLE = 'ENDURSLMT_I_POWER';
export const ENDURAU_STATIC_MAP_NCF = 'ENDURAU_STATIC_MAP_NCF';
export const ENDURAU_I_GAS = 'ENDURAU_I_GAS';
export const NOT_UPDATED = 'NOT UPDATED';
export const QTY = 'QTY';
export const DEAL_QUANTITY = 'DEAL_QUANTITY';
export const MARKET = 'MARKET';
export const NCF_CATEGORY = 'NCF_CATEGORY';
export const BUYSELL = 'BUYSELL';
export const BUY_SLASH_SELL = 'BUY/SELL';
export const BUY = 'BUY';
export const SELL = 'SELL';
export const COMMENTS = 'COMMENTS';
export const ID = 'ID';
export const SCOPE = 'SCOPE';
export const NCF_SCOPE = 'NCF_SCOPE';
export const COUNTRY_CODE = 'COUNTRY_CODE';
export const COUNTRY_NAME = 'COUNTRY_NAME';
export const NCF_SNAPSHOT_ID = 'NCF_SNAPSHOT_ID';
export const SNAPSHOT_STATUS = 'SNAPSHOT_STATUS';
export const APPROVED = 'APPROVED';
export const REJECTED = 'REJECTED';
export const APPROVED_UI = 'Approved';
export const REJECTED_UI = 'Rejected';
export const RE_SUBMITTED_FOR_APPROVAL = 'RE_SUBMITTED_FOR_APPROVAL';
export const RE_SUBMITTED_FOR_APPROVAL_UI = 'Resubmitted for approval';
export const SUBMITTED_FOR_APPROVAL = 'SUBMITTED_FOR_APPROVAL';
export const SUBMITTED_FOR_APPROVAL_UI = 'Submitted for approval';
export const OUT_OF_SCOPE = '0 (OUT OF SCOPE)';
export const IN_SCOPE = '1 (IN SCOPE)';
export const RULE_CONFIG_ID = 'RULE_CONFIG_ID';
export const COUNTERPARTY_SHORT_NAME = 'COUNTERPARTY_SHORT_NAME';
export const TRADE_TYPE = 'TRADE_TYPE';
export const STORAGE = 'STORAGE';
export const START_DATE = 'START_DATE';
export const END_DATE = 'END_DATE';
export const CHANGE_ATTRIBUTE = 'CHANGE_ATTRIBUTE';
export const CHANGE_FROM = 'CHANGE_FROM';
export const CHANGE_TO = 'CHANGE_TO';
export const STATUS = 'STATUS';
export const VOLUME = 'VOLUME';
export const ARUBA = 'Aruba';
export const ANGUILLA = 'Anguilla';
export const ARGENTINA = 'Argentina';
export const ANTIGUA_AND_BARBUDA = 'Antigua and Barbuda';
export const THE_BAHAMAS = 'The Bahamas';
export const SAINT_BARTHELEMY = 'Saint Barthelemy';
export const BELIZE = 'Belize';
export const BERMUDA = 'Bermuda';
export const BOLIVIA = 'Bolivia';
export const BRAZIL = 'Brazil';
export const BRAZIL_CODE = 'BRA';
export const BARBADOS = 'Barbados';
export const CANADA = 'Canada';
export const CANADA_CODE = 'CAN';
export const CHILE = 'Chile';
export const COLOMBIA = 'Colombia';
export const COSTA_RICA = 'Costa Rica';
export const CUBA = 'Cuba';
export const CURACAO = 'Curaçao';
export const CAYMAN_ISLANDS = 'Cayman Islands';
export const DOMINICA = 'Dominica';
export const DOMINICAN_REPUBLIC = 'Dominican Republic';
export const ECUADOR = 'Ecuador';
export const FALKLAND_ISLANDS = 'Falkland Islands';
export const GRENADA = 'Grenada';
export const GREENLAND = 'Greenland';
export const GUATEMALA = 'Guatemala';
export const GUYANA = 'Guyana';
export const HONDURAS = 'Honduras';
export const HAITI = 'Haiti';
export const JAMAICA = 'Jamaica';
export const SAINT_KITTS_AND_NEVIS = 'Saint Kitts and Nevis';
export const SAINT_LUCIA = 'Saint Lucia';
export const SAINT_MARTIN = 'Saint Martin';
export const MEXICO = 'Mexico';
export const MEXICO_CODE = 'MEX';
export const MONTSERRAT = 'Montserrat';
export const NICARAGUA = 'Nicaragua';
export const PANAMA = 'Panama';
export const PERU = 'Peru';
export const PUERTO_RICO = 'Puerto Rico';
export const PARAGUAY = 'Paraguay';
export const EL_SALVADOR = 'El Salvador';
export const SAINT_PIERRE_AND_MIQUELON = 'Saint Pierre and Miquelon';
export const SURINAME = 'Suriname';
export const SINT_MAARTEN = 'Sint Maarten';
export const TURKS_AND_CAICOS_ISLANDS = 'Turks and Caicos Islands';
export const TRINIDAD_AND_TOBAGO = 'Trinidad and Tobago';
export const URUGUAY = 'Uruguay';
export const USA = 'United States of America';
export const USA_CODE = 'USA';
export const SAINT_VINCENT_AND_THE_GRENADINES = 'Saint Vincent and the Grenadines';
export const VENEZUELA = 'Venezuela';
export const BRITISH_VIRGIN_ISLANDS = 'British Virgin Islands';
export const UNITED_STATES_VIRGIN_ISLANDS = 'United States Virgin Islands';
export const FEEDBACK_LINK =
  'https://forms.office.com/pages/responsepage.aspx?id=qJYe29qjKkSTCyNcrCTNXLoU9VATCy1DlHtfVrhCZV5UN0ZOTFBSMEIwSzNKOUZGMTZNVFFaNTNBRSQlQCN0PWcu';
export const REGION_SHORT_NAMES = {
  EUROPE: 'EU',
  AMERICAS: 'AM',
  ASIA: 'AS',
  JAPAN: 'JP',
  AUSTRALIA: 'AU',
};
export const ASIA_JAPAN_SHORT_NAME = 'AS-JP';
export const DATA_SNAPSHOT_REGIONS = {
  EUROPE: 'EU',
  AMERICAS: 'AM',
  AUSTRALIA: 'AU',
  'ASIA-JAPAN': ASIA_JAPAN_SHORT_NAME,
};
export const SELECT = 'SELECT';
export const AMERICAS = 'AMERICAS';

export const ASIA = 'ASIA';
export const EUROPE = 'EUROPE';
export const AUSTRALIA = 'AUSTRALIA';
export const AUSTRALIA_SMALLCASE = 'Australia';
export const GLOBAL = 'GLOBAL';
export const SUPPORT = 'SUPPORT';
export const SLMT = 'SLMT';
export const SPECIFIC_REGIONS = [ASIA, AMERICAS, AUSTRALIA, EUROPE];
export const OTHER_REGIONS = [GLOBAL, SUPPORT];
export const JAPAN = 'JAPAN';
export const ASIA_JAPAN = 'ASIA-JAPAN';
export const ASIA_JAPAN_SMALLCASE = 'Asia-Japan';
export const ASIA_JAPAN_REGION_LONG_NAME = 'asia-japan';
export const COMMODITY_POWER_SHORT_NAME = 'PW';
export const COMMODITY_NG_SHORT_NAME = 'NG';
export const COMMODITY_POWER_LONG_NAME = 'Power';
export const COMMODITY_NG_LONG_NAME = 'Natural Gas';
export const NCF_VOLUME = 'NCF Volume';
export const REGION_IN_SCOPE = [EUROPE, AMERICAS, ASIA];

export const ROLE_NCF_AMERICAS = 'TANSO_NCF_AMERICAS';
export const ROLE_NCF_ASIA = 'TANSO_NCF_ASIA';
export const ROLE_NCF_EUROPE = 'TANSO_NCF_EUROPE';
export const ROLE_NCF_AUSTRALIA = 'TANSO_NCF_AUSTRALIA';
export const ROLE_IT_SUPPORTED = 'TANSO_IT_SUPPORT';
export const ROLE_IT_GLOBAL = 'TANSO_IT_GLOBAL';
export const SECTORAL_PERFORMANCE_DESC =
  'Aim of Sectoral Performance is to provide a single view of the customer across all lines of business, including KPIs like NCF volumes, NCF and Revenue data. As a result, this feature is implemented to allow users to share the sectoral performance report on quarterly basis along with the list of counterparties as an input towards sectoral mapping exercise.';
export const API_VERSION_ONE = 'api/v1/';
export const API_VERSION_TWO = 'api/v2/';

export const CLICK_HERE = 'CLICK HERE';
export const REQUEST_ACCESS_MESSAGE =
  'to request for access and select "Tanso"  in the application dropdown.';
export const SOM_MAIL_ID = 'DST&SApplicationSupport@shell.com';
export const INVALID = 'Invalid';
export const REPORT_IN_PROGRESS_MESSAGE =
  'Report generation in progress. This might take few minutes.';
export const REPORT_SUCCESS_MESSAGE =
  'Report has been generated successfully. You may now download the report.';
export const REPORT_FAILURE_MESSAGE = 'Report failed to generate! You may mail to ';
export const SECTORAL_STEP02_DESC =
  "This is an optional step but should you choose to upload the files, only GoldTier file is mandatory. Upload Mapping Sheet in the file format 'GoldTierMapping_DDMMYYYY' and 'NACEMapping_DDMMYYYY' of size less than 5 MB.";
export const SECTORAL_STEP01_DESC =
  'Download the counterparty list. You can either download the entire counterparty list or the list of ones that are not mapped to any sector.';
export const IN_PROGRESS = 'in_progress';
export const ZERO = 1;
export const ONE = 1;
export const TWO = 2;
export const THREE = 3;
export const FOUR = 4;
export const FIVE = 5;
export const SIX = 6;
export const SEVEN = 7;
export const EIGHT = 8;
export const NINE = 9;
export const TEN = 10;
export const ELEVEN = 11;
export const TWENTY_SIX = 26;
export const EIGHTY = 80;
export const HUNDRED = 100;
export const ONE_TWO_THREE = 123;
export const FOUR_FIVE_SIX = 456;
export const FOUR_HUNDRED = 400;
export const THOUSAND = 1000;
export const THOUSAND_TWENTY_FOUR = 1024;
export const THOUSAND_FIVE_HUNDRED = 1500;
export const TWO_THOUSAND = 2000;
export const FIVE_THOUSAND = 5000;
export const TEN_THOUSAND = 10000;
export const FIFTEEN_THOUSAND = 15000;
export const YEAR_2023 = 2023;
export const YEAR_2024 = 2024;
export const YEAR_2022 = 2022;

export const CONFIGURE_PAGE_DESCRIPTION_1 =
  'In the specific case of Europe, Some Fields in I_Aligne for specific counterparties need to be changed for NCF Eligible Volume Reporting purposes. Those changes are made in Tanso as the deals are booked in a different way in the ETRM system.';
export const CONFIGURE_PAGE_DESCRIPTION_2 =
  'This feature has therefore been implemented to apply a specific rule   to those use cases where bulk changes must be made all at once to the underling dataset. This feature can also be used to Edit the Rule logic.';
export const QTY_ERROR_MESSAGE =
  'QUANTITY (QTY) / DEAL QUANTITY / VOLUME : Can not be empty. Whole number must not exceed 25 characters. Decimal number must not exceed 10 characters.';
export const COMMENTS_ERROR_MESSAGE = 'COMMENTS: Mandatory field for a modified row.';
export const COUNTRY_NAME_ERROR_MESSAGE = 'COUNTRY NAME : Can not be empty when Scope is ';
export const QTY_WARNING_MESSAGE = 'Warning : You are trying to give a large number for QTY !!';

export const TANSO_REPORTING_TOOL = 'Tanso Reporting Tool';
export const SELECT_ROLE_MESSAGE = 'Please select an user role to access Tanso';
export const CONTINUE = 'Continue';

export const DATA_SNAPSHOT_PAGE_DESCRIPTION =
  'Use this page to create a ‘Snapshot’ of the data that will be submitted for NCF approval at a particular point in time. The reason a Snapshot must be taken is because Tanso data is refreshed on a daily basis and so a Snapshot taken today, may appear differently to a Snapshot taken tomorrow. It is therefore important that the system maintains a record of the data submitted for approval.';

export const CREATE_SNAPSHOT = 'Create Snapshot';
export const UNAUTHORISED = 'unauthorized';
export const MASTER_DATA_RECORDS = 'Master Data Records';
export const CONFIG_RULE_MENU = 'Configure Rules for Reporting';
export const SECTORAL_MENU = 'Sectoral Performance';
export const NCF_SNAPSHOT_MENU = 'NCF Snapshot';
export const PPA_MENU = 'PPA Insights';
export const REGION_NAMES = {
  ASIA: 'ASIA',
  AMERICAS: 'AMERICAS',
  EUROPE: 'EUROPE',
  AUSTRALIA: 'AUSTRALIA',
};
export const allowedUserMails = [
  { userMail: 'rama-prasad.nayak@shell.com', userRegion: 'Americas' },
  { userMail: 'nitish.n.raj@shell.com', userRegion: 'Asia' },
  { userMail: 'md.m.irfan@shell.com', userRegion: 'Europe' },
  { userMail: 'm.sunnypanguluri@shell.com', userRegion: 'Australia' },
  { userMail: 'amit.sarur@shell.com', userRegion: 'Europe' },
  { userMail: 'sk-sabir.ali@shell.com', userRegion: 'Europe' },
  { userMail: 'abhilasha.kanaskar@shell.com', userRegion: 'Europe' },
  { userMail: 'swati.tyagi@shell.com', userRegion: 'Europe' },
  { userMail: 'camilla.torre@shell.com', userRegion: 'Europe' },
];
