/* eslint-disable no-unused-vars */
import React from 'react';
import { IProduct } from '../../../Types/types';
import RowGroups from '../ScenerioTable';
import { useAppDispatch } from '../../../redux/hooks';
import { setScenarioModellingTabTitle } from '../../../redux/scenarioModelling/scenarioModellingSlice';
import { BUSINESS_PLAN_TITLE } from '../../../constants';

interface BusinessPlanProps {
  selectedRegion: string;
  selectedRegionData: IProduct;
  handleValueChangeWrapper: (
    rowId: string,
    category: string,
    product: string,
    year: number,
    newValue: number,
  ) => void;
  handleRevertToOriginalWrapper: (
    rowId: string,
    category: string,
    product: string,
    year: number,
  ) => void;
}
const BusinessPlan: React.FC<BusinessPlanProps> = ({
  selectedRegion,
  selectedRegionData,
  handleValueChangeWrapper,
  handleRevertToOriginalWrapper,
}) => {
  const dispatch = useAppDispatch();
  dispatch(setScenarioModellingTabTitle(BUSINESS_PLAN_TITLE));
  const getTableData = () => {
    return selectedRegionData?.categoryData?.map((categoryData) => {
      const { product, category, yearsdata } = categoryData;
      return {
        id: product,
        category,
        product,
        yearsdata,
      };
    });
  };
  return (
    <div data-testid='business-plan'>
      {selectedRegionData && (
        <RowGroups
          tableData={getTableData()}
          selectedRegion={selectedRegion}
          onInputChange={handleValueChangeWrapper}
          onRevertToOriginal={handleRevertToOriginalWrapper}
        />
      )}
    </div>
  );
};

export default BusinessPlan;
