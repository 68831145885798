import {
  IPptAction,
  IPptData,
  IPptDataPayload,
  IPptFilteredValues,
  IPptFilterOptions,
  IPptNcfCategories,
  IPptNcfVolumes,
  IPptInScopeVsOutScopeVolume,
} from '../../Types/types';

const PptActionHandlers: Record<
  string,
  // eslint-disable-next-line no-unused-vars
  (state: IPptData, payload: IPptDataPayload) => IPptData
> = {
  setPptFilterOptions: (state, payload) => ({
    ...state,
    PptfilterOptions: payload as IPptFilterOptions,
  }),
  setPptFilteredValues: (state, payload) => ({
    ...state,
    PptfilteredValues: payload as IPptFilteredValues,
  }),
  setPptNcfCategories: (state, payload) => ({
    ...state,
    pptNcfCategories: payload as IPptNcfCategories[],
  }),
  setPptNcfVolumes: (state, payload) => ({
    ...state,
    pptNcfVolumes: payload as IPptNcfVolumes[],
  }),
  // Add the new reducer action here
  setPptReportNames: (state, payload) => ({
    ...state,
    pptReportNames: payload as string[], // Assuming the state has a property for storing report names
  }),
  setPptInScopeVsOutScopeVolume: (state, payload) => ({
    ...state,
    pptInScopeVsOutScopeVolume: payload as IPptInScopeVsOutScopeVolume[],
  }),
};

export const PptReportsReducers = (state: IPptData, action: IPptAction) => {
  if (Object.hasOwn(PptActionHandlers, action.type)) {
    const handler = PptActionHandlers[action.type];
    return handler(state, action.payload);
  }
  return state;
};
