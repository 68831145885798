import { Dispatch } from '@reduxjs/toolkit';
import { setField } from '../../redux/commodityDetails/commodityDetailsSlice';
import { CommodityFilteredValue } from '../../redux/commodityDetails/commodityDetails.types';
import dayjs, { Dayjs } from 'dayjs';
import { nine, two } from '../../constants';
import {
  DefaultOptionType,
  ICommodityFilters,
  IFilterData,
  IFilterField,
  IFilterFieldsArguments,
  IFilterOptions,
  ITradeDataRegion,
  RangeValue,
} from '../../Types/types';
import { filterTargetDataByRegion, getFilterOptions, getRegionFromSeIpu } from './Utils';

//onchange of Commodity dropdown
export const handleCommodityChange = async (
  e: string,
  selectedOption: DefaultOptionType,
  sauOptions: string[],
  dispatch: Dispatch,
) => {
  dispatch(
    setField({
      commodityVal: e,
      commodityId: selectedOption?.key ?? '',
      sauVal: sauOptions[0],
      evpVal: 'ALL',
      seIpuRegionVal: 'ALL',
      bizVal: 'ALL',
      firstDate: null,
      lastDate: null,
      disableHandleFilter: true,
      isHandleApply: false,
      sauId: sauOptions[0],
      evpId: '',
      seIpuRegionId: '',
      bizId: '',
    }),
  );
  resetYear(dispatch);
};

//onchange of REGION dropdown
export const handleSauChange = async (
  e: string,
  selectedOption: DefaultOptionType,
  dispatch: Dispatch,
) => {
  dispatch(
    setField({
      sauVal: e,
      sauId: selectedOption?.key ?? '',
      vpId: '',
      vpVal: '',
    }),
  );
  resetYear(dispatch);
};

export const handleEVPChange = async (
  e: string,
  commodityVal: string,
  selectedOption: DefaultOptionType,
  masterCommodityFilteredValue: CommodityFilteredValue[],
  sauId: string,
  dispatch: Dispatch,
  seIpuRegionId?: string,
) => {
  let filterSeIpuRegion = masterCommodityFilteredValue.filter((item) =>
    e === 'ALL'
      ? item.SAU === sauId
      : item.EVP === e && item.SAU === sauId && item.TRADE_COMMODITY_NAME === commodityVal,
  );
  const seIpuRegionOptions = [
    'ALL',
    ...getFilterOptions(filterSeIpuRegion as unknown as IFilterOptions[], 'SE_IPU_Region'),
  ];
  if (seIpuRegionId && seIpuRegionId !== 'ALL') {
    filterSeIpuRegion = filterSeIpuRegion.filter((item) => item.SE_IPU_Region === seIpuRegionId);
  }
  const businessOptions = [
    'ALL',
    ...getFilterOptions(filterSeIpuRegion as unknown as IFilterOptions[], 'REPORTING_ENTITY_NAME'),
  ];
  dispatch(
    setField({
      evpVal: e,
      evpId: selectedOption?.key ?? '',
      seIpuRegionOptions,
      bizVal: 'ALL',
      businessOptions,
      bizId: '',
    }),
  );
  resetYear(dispatch);
};

// onChange of SE IPU Region dropdown
export const handleSeIpuRegionChange = async ({
  e,
  commodityVal,
  selectedOption,
  masterCommodityFilteredValue,
  sauId,
  evpId,
  carbonIntensityTarget,
  dispatch,
}: {
  e: string;
  commodityVal: string;
  selectedOption: DefaultOptionType;
  masterCommodityFilteredValue: CommodityFilteredValue[];
  sauId: string;
  evpId: string;
  carbonIntensityTarget: ITradeDataRegion[];
  dispatch: Dispatch;
}) => {
  const region = getRegionFromSeIpu(e);
  const filterData = filterTargetDataByRegion(region, carbonIntensityTarget);
  dispatch(setField({ key: 'carIntTargetPerRegion', value: filterData }));
  // Simplify filtering logic by directly applying all conditions in a single filter operation.
  const filterConditions = (item: CommodityFilteredValue) => {
    let matchesRegion = false;
    if (e === 'ALL') {
      matchesRegion = true;
    } else {
      matchesRegion = e
        ? item.SE_IPU_Region === e && item.TRADE_COMMODITY_NAME === commodityVal
        : true;
    }
    const matchesSAU = item.SAU === sauId;
    const matchesEVP = evpId && evpId !== 'ALL' ? item.EVP === evpId : true;

    return matchesRegion && matchesSAU && matchesEVP;
  };

  const filteredCommodities = masterCommodityFilteredValue.filter(filterConditions);

  const businessOptions = [
    'ALL',
    ...getFilterOptions(
      filteredCommodities as unknown as IFilterOptions[],
      'REPORTING_ENTITY_NAME',
    ),
  ];
  dispatch(
    setField({
      seIpuRegionVal: e,
      seIpuRegionId: selectedOption?.key ?? '',
      businessOptions,
      bizVal: 'ALL',
      bizId: '',
    }),
  );
  resetYear(dispatch);
};

// Handle Reporting Entity onChange
export const handleBusinessChange = async (
  e: string,
  selectedOption: DefaultOptionType,
  masterCommodityFilteredValue: IFilterData[],
  dispatch: Dispatch,
) => {
  const selctedData =
    e === 'ALL'
      ? masterCommodityFilteredValue
      : masterCommodityFilteredValue.filter((item) => item.REPORTING_ENTITY_NAME === e);
  const evpVal = e === 'ALL' ? 'ALL' : selctedData[0]?.EVP;
  const seIpuRegionVal = e === 'ALL' ? 'ALL' : selctedData[0]?.SE_IPU_Region;
  dispatch(
    setField({
      bizVal: e,
      bizId: selectedOption?.key ?? '',
      evpVal,
      evpId: evpVal,
      seIpuRegionVal,
      seIpuRegionId: seIpuRegionVal,
    }),
  );
  resetYear(dispatch);
};

export const getFilterFields = ({
  commodityVal,
  sauVal,
  evpVal,
  seIpuRegionVal,
  bizVal,
  commodityOptions,
  sauOptions,
  evpOptions,
  seIpuRegionOptions,
  businessOptions,
  isFetchingEVP,
  isFetchingSeIpuRegion,
  isFetchingBiz,
  isDisabledSau,
  dispatch,
  sauId,
  evpId,
  seIpuRegionId,
  masterCommodityFilteredValue,
  carbonIntensityTarget,
}: IFilterFieldsArguments): IFilterField[] => [
  {
    id: 'commodityVal',
    label: 'Commodity',
    value: commodityVal,
    options: commodityOptions.filter((x) => x !== 'Hydrogen'),
    onChange: async (e, selectedOption) => {
      await handleCommodityChange(e, selectedOption, sauOptions, dispatch);
    },
  },
  {
    id: 'sau',
    label: 'SAU',
    value: sauVal,
    disabled: isDisabledSau,
    options: sauOptions,
    loading: isFetchingEVP,
    onChange: async (e, selectedOption) => {
      await handleSauChange(e, selectedOption, dispatch);
    },
  },
  {
    id: 'evp',
    label: 'EVP',
    value: evpVal,
    options: evpOptions,
    loading: isFetchingEVP,
    onChange: async (e, selectedOption) => {
      await handleEVPChange(
        e,
        commodityVal,
        selectedOption,
        masterCommodityFilteredValue,
        sauId,
        dispatch,
        seIpuRegionId,
      );
    },
  },
  {
    id: 'seIpuRegion',
    label: 'SE IPU Region',
    value: seIpuRegionVal,
    options: seIpuRegionOptions,
    loading: isFetchingSeIpuRegion,
    onChange: async (e, selectedOption) => {
      await handleSeIpuRegionChange({
        e,
        commodityVal,
        selectedOption,
        masterCommodityFilteredValue,
        sauId,
        evpId,
        carbonIntensityTarget,
        dispatch,
      });
    },
  },
  {
    id: 'reportingEntity',
    label: 'Reporting Entity',
    value: bizVal,
    options: businessOptions,
    loading: isFetchingBiz,
    onChange: async (e, selectedOption) => {
      await handleBusinessChange(e, selectedOption, masterCommodityFilteredValue, dispatch);
    },
  },
];

export const clearFilter = (
  dispatch: Dispatch,
  masterCommodityFilteredValue: IFilterData[],
  carbonIntensityTarget: ITradeDataRegion[],
) => {
  dispatch(setField({ key: 'commodityVal', value: 'Power' }));
  const commodityFiltered = masterCommodityFilteredValue.filter(
    (reg: IFilterData) => reg.TRADE_COMMODITY_NAME === 'Power',
  );
  const sauOptions = getFilterOptions(commodityFiltered as unknown as IFilterOptions[], 'SAU');
  const evpOptions = [
    'ALL',
    ...getFilterOptions(commodityFiltered as unknown as IFilterOptions[], 'EVP'),
  ];
  const seIpuRegionOptions = [
    'ALL',
    ...getFilterOptions(commodityFiltered as unknown as IFilterOptions[], 'SE_IPU_Region'),
  ];
  const businessOptions = [
    'ALL',
    ...getFilterOptions(commodityFiltered as unknown as IFilterOptions[], 'REPORTING_ENTITY_NAME'),
  ];
  const region = getRegionFromSeIpu('ALL');
  const filterData = filterTargetDataByRegion(region, carbonIntensityTarget);
  dispatch(
    setField({
      commodityFilteredValue: commodityFiltered,
      sauVal: sauOptions[0],
      sauId: sauOptions[0],
      evpVal: 'ALL',
      seIpuRegionVal: 'ALL',
      bizVal: 'ALL',
      commodityId: '',
      evpId: '',
      seIpuRegionId: '',
      bizId: '',
      evpOptions,
      seIpuRegionOptions,
      businessOptions,
      isHandleApply: false,
      disableHandleFilter: true,
      carIntTargetPerRegion: filterData,
    }),
  );
  resetYear(dispatch);
};

export const handleDateChange = (e: RangeValue<Dayjs> | null, dispatch: Dispatch) => {
  if (e !== null) {
    const startYear = e[0]?.year();
    const endYear = e[1]?.year();
    dispatch(
      setField({
        disableHandleFilter: false,
        firstDate: startYear,
        lastDate: endYear,
      }),
    );
    const startDate = dayjs(startYear?.toString());
    const endDate = dayjs(endYear?.toString());
    dispatch(setField({ key: 'yearsRange', value: [startDate, endDate] }));
  } else {
    dispatch(setField({ key: 'disableHandleFilter', value: true }));
  }
};

export const resetYear = (dispatch: Dispatch) => {
  dispatch(
    setField({
      yearsRange: [null, null],
      firstDate: null,
      lastDate: null,
      isHandleApply: false,
      disableHandleFilter: true,
    }),
  );
};

export const handleApplyFilter = (
  commodityFilteredValue: IFilterData[],
  firstDate: Date | null,
  lastDate: Date | null,
  dispatch: Dispatch,
) => {
  let newChartdata = [];
  newChartdata = commodityFilteredValue.filter(
    (lst: IFilterData) =>
      Number(lst.TRADE_YEAR) >= Number(firstDate) && Number(lst.TRADE_YEAR) <= Number(lastDate),
  );
  dispatch(setField({ commodityFilteredValue: newChartdata, isHandleApply: true }));
};

const minDate = dayjs().subtract(two, 'year');
const maxDate = dayjs().add(nine, 'year');

export const disabledFutureDate = (current: Dayjs | null) => {
  return current ? current.isBefore(minDate) || current.isAfter(maxDate) : false;
};

export const filterCommodity = (filters: ICommodityFilters, data: CommodityFilteredValue[]) => {
  // Preprocess filters: replace "ALL" with an empty string
  const processedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
    acc[key as keyof ICommodityFilters] = value === 'ALL' ? '' : value;
    return acc;
  }, {} as ICommodityFilters);
  return data.filter((item: CommodityFilteredValue) => {
    for (const key in processedFilters) {
      if (
        processedFilters[key as keyof ICommodityFilters] &&
        item[key as keyof CommodityFilteredValue] !==
          processedFilters[key as keyof ICommodityFilters]
      ) {
        return false;
      }
    }
    return true;
  });
};
