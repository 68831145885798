import React, { useEffect, useContext } from 'react';
import { TooltipFormatterContextObject } from 'highcharts';
import { ChartContainer, TableHeading } from './CiTradesChart.style';
import { ciTradeChartData } from '../../../../../../api/services';
import {
  groupAndSortDataByParameter,
  generateSeries,
  getChartXAxisCategories,
  IRegionQuarterData,
  tooltipFormatter,
} from './CiTradesUtils';
import Chart from '../../../Chart/Chart';
import { Flexbox } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import { ICiTradeChartData } from '../../../../../../Types/types';

const CiTradesChart: React.FC = () => {
  const { state: analyticsState, dispatch: analyticsDispatch } =
    useContext(AppContext).ciTradeAndVolume;

  const mapData: ICiTradeChartData[] = analyticsState.ciTradeChartData;

  useEffect(() => {
    const fetchData = async () => {
      let response;
      try {
        response = await ciTradeChartData(analyticsState.ciTradeFilteredValues);

        // Use the analyticsDispatch function to call setCiTradeChartData
        analyticsDispatch({
          type: 'setCiTradeChartData',
          payload: response.data,
        });
      } catch (error) {
        // Log error once toast feature is implemented
      }
    };

    fetchData();
  }, [analyticsDispatch, analyticsState.ciTradeFilteredValues]); // Add analyticsDispatch to the dependency array

  // Group and sort data by region
  const dataByRegion = groupAndSortDataByParameter(mapData);
  const series = generateSeries(dataByRegion);
  const titleText = 'Year';

  const options = {
    chart: {
      type: 'column',
    },
    xAxis: {
      categories: getChartXAxisCategories(dataByRegion as Record<string, IRegionQuarterData[]>),
      crosshair: true,
      title: {
        text: titleText,
      },
    },
    yAxis: {
      title: {
        text: `Deal Quantity`,
      },
      minRange: 0.1,
    },
    tooltip: {
      shared: false,
      // eslint-disable-next-line no-unused-vars
      formatter(this: TooltipFormatterContextObject) {
        return tooltipFormatter(this);
      },
    },
    plotOptions: {
      series: {
        pointWidth: 20,
        pointPadding: 0.2, // Padding between each column or bar, defaults to 0.1
        groupPadding: 0.2, // Padding between each value groups, in x axis units, defaults to 0.2
        dataLabels: {
          enabled: true,
          format: '{point.y:.2f}', // upto 2 decimal points
        },
      },
    },
    series,
  };

  return (
    <ChartContainer data-testid='map-container'>
      <Flexbox style={{ width: '100%' }} justifyContent='space-between'>
        <TableHeading>CI Trades Chart</TableHeading>
      </Flexbox>
      <Chart options={options} footer={'Source'} />
    </ChartContainer>
  );
};

export default CiTradesChart;
