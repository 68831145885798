import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import { ShellFont, toolTipFormatter } from '../../../utils';
import Chart from '../../Chart/Chart';
import { CHART_AXIS_STYLE, point5, POWER_DISCLAIMER } from '../../../constants';
import { ChartData, PointsData } from '../../../Types/types';
import { StyledDiv, ChartContainer } from '../ScenerioModellingChart/index.styles';
import { currentYear } from '../../../utils/utils';

interface ScenerioModellingChartProps {
  netCarbonIntensity: ChartData[];
}

const NetCarbonIntensity: React.FC<ScenerioModellingChartProps> = ({ netCarbonIntensity }) => {
  const reducedArray = netCarbonIntensity[0].data.map(({ YEAR }) => ({ YEAR }));
  const uniqueYears = reducedArray.map((x) => x?.YEAR?.toString());
  const baseCase = {
    ...netCarbonIntensity[0],
    color: '#E4494C',
    dashStyle: 'Dash',
    data: netCarbonIntensity[0]?.data.map((item) => item.CHANGED_REPORTED_GROUP_NCI),
    lineWidth: 1.5,
  };
  const senarioCase = {
    ...netCarbonIntensity[1],
    color: '#0097A9',
    dashStyle: 'Solid',
    data: netCarbonIntensity[1]?.data.map((item) => item.CHANGED_REPORTED_GROUP_NCI),
    lineWidth: 6,
    lineColor: 'rgba(0, 151, 169, 0.2)',
  };
  const columeRange = {
    ...netCarbonIntensity[2],
    color: '#0097A9',
    dashStyle: 'Solid',
    showInLegend: false,
  };
  const [indexOfLastActuals, setIndexOfLastActuals] = useState(-1);
  useEffect(() => {
    if (netCarbonIntensity.length > 0) {
      setIndexOfLastActuals(
        netCarbonIntensity[0]['data']
          .slice()
          .reverse()
          .findIndex((item) => item.YEAR !== undefined && item.YEAR < currentYear),
      );
    }
  }, [netCarbonIntensity]);

  let lowerLimit = senarioCase.data && Math.min(...(senarioCase?.data as number[]));
  let upperLimit = senarioCase.data && Math.max(...(senarioCase?.data as number[]));

  lowerLimit = Math.floor(lowerLimit / 10) * 10;
  upperLimit = Math.ceil(upperLimit / 10) * 10;
  const options: Highcharts.Options = {
    chart: {
      type: 'line',
      zooming: {
        type: 'xy',
      },
      height: 500,
      marginBottom: 100,
      style: {
        fontFamily: 'Shell Font, Arial, sans-serif',
      },
    },
    title: {
      text: 'Scenario - Net Carbon Intensity',
      align: 'left',
    },
    subtitle: {
      align: 'left',
      text: 'An overview of Base Case NCI Target vs Scenario NCI Target.',
      style: {
        fontSize: '14px',
        fontFamily: ShellFont,
      },
    },
    legend: {
      itemStyle: {
        fontSize: '12px',
        fontWeight: 'light',
        color: '#757575',
        fontFamily: 'Shell Font, Arial, sans-serif',
      },
    },
    yAxis: {
      title: {
        useHTML: true,
        text: 'NCI Targets (gCO2e/MJ)',
        style: CHART_AXIS_STYLE,
      },
      min: lowerLimit,
      max: upperLimit,
    },
    xAxis: {
      categories: uniqueYears as string[],
      plotBands:
        indexOfLastActuals !== -1
          ? [
              {
                color: 'rgba(128, 128, 128, 0.3)', // Gray background for historical section
                from: -point5,
                to: uniqueYears.length - 1 - indexOfLastActuals + point5,
                label: {
                  text: 'Actuals',
                  style: {
                    fontFamily: ShellFont,
                    fontSize: '11px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    textAlign: 'right',
                    color: '#343434',
                  },
                  verticalAlign: 'bottom', // Label below the y axis
                  y: 40, // Adjust label position
                },
              },

              {
                color: 'white', // Gray background for historical section
                from: uniqueYears.length - indexOfLastActuals - point5,
                to: uniqueYears.length - 1 + point5,
                label: {
                  text: 'Plan',
                  style: {
                    fontFamily: ShellFont,
                    fontSize: '11px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    textAlign: 'right',
                    color: '#343434',
                  },
                  verticalAlign: 'bottom', // Label below the y axis
                  y: 40, // Adjust label position
                },
              },
            ]
          : [
              {
                color: 'white', // Gray background for historical section
                from: -point5,
                to: uniqueYears.length - 1 + point5, // To final year
                label: {
                  text: 'Plan',
                  style: {
                    fontFamily: ShellFont,
                    fontSize: '11px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    textAlign: 'right',
                    color: '#343434',
                  },
                  verticalAlign: 'bottom', // Label below the y axis
                  y: 40, // Adjust label position
                },
              },
            ],
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
      valueDecimals: 2,
      formatter() {
        return toolTipFormatter(this as unknown as PointsData, true);
      },
    },
    plotOptions: {
      area: {
        stacking: 'normal',
        lineColor: '#666666',
        lineWidth: 1,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series: [
      { ...columeRange, inverted: true, showInLegend: false },
      senarioCase,
      baseCase,
    ] as Highcharts.SeriesOptionsType[],
  };
  return (
    <StyledDiv>
      <ChartContainer>
        <Chart
          chartKey={'nci-chart'}
          highcharts={Highcharts}
          options={options}
          source={'Scenario Table'}
          data-testid='nci-chart'
          disclaimer={[POWER_DISCLAIMER]}
        />
      </ChartContainer>
    </StyledDiv>
  );
};

export default NetCarbonIntensity;
