import { IFilterOptions, ITradeDataRegion } from '../../Types/types';
import { POWER_INTENSITY_MODEL, SE_GLOBAL_SBTI } from '../../api/constants';
import { pipeLineGas, regionOptions } from '../../constants';
import { SummedValues } from '../charts/CarbonIntensityChart/types';
import { ProcessedDataItem } from '../charts/VolumeCarbonEmissionChart/types';

//filter target data by region
export const filterTargetDataByRegion = (
  region: string,
  carbonIntensityTarget: ITradeDataRegion[],
) => {
  const carbonIntensityData = carbonIntensityTarget;
  let firstFilteredData = [];
  if (Array.isArray(carbonIntensityData)) {
    const filteredData = carbonIntensityData.filter((item) => {
      return item.REGION.includes(region);
    });
    if (filteredData.length > 0) {
      firstFilteredData = [
        {
          year: filteredData[0].TRADE_YEAR.toString(),
          type: 'Target',
          total_value: filteredData[0].TARGET_VALUE,
          region: region === SE_GLOBAL_SBTI ? 'Global' : region,
        },
      ];
    } else {
      firstFilteredData = [
        {
          year: 2030,
          type: 'Target',
          total_value: 0,
          region: region === SE_GLOBAL_SBTI ? 'Global' : region,
        },
      ];
    }
  } else {
    firstFilteredData = [{ year: '0', type: 'Target', total_value: 0 }];
  }
  return firstFilteredData;
};

export const getFilterOptions = (data: IFilterOptions[], key: keyof IFilterOptions): string[] => {
  return data
    .flatMap((val) => (val[key] !== null ? [val[key] as string] : []))
    .filter((v, index, self) => self.indexOf(v) === index);
};
export const getRegionFromSeIpu = (seIpu: string) => {
  const index = regionOptions.findIndex((item) => seIpu.includes(item));
  return index === 0 ? SE_GLOBAL_SBTI : regionOptions[index]?.toUpperCase();
};

export const getRename = (name: string) => {
  if (
    ['External Gas Sales Volumes (Pipeline)', 'Inland Sales Volumes - Pipeline Gas'].includes(name)
  ) {
    return 'Pipeline Gas';
  } else if (name === 'External Gas Sales Volumes (Renewable)') {
    return 'Renewable Gas';
  } else {
    return name;
  }
};

export const getTransformSource = (data: string): string => {
  const sourceArray = data?.split(',').map((item) => item.trim());
  const isPowerIntensityModelAndTanso = [POWER_INTENSITY_MODEL, 'Tanso'].every((item) =>
    sourceArray.includes(item),
  );
  const updatedData = Array.from(
    new Set(
      sourceArray.map((item) => {
        if (item.includes('LE_SE') || item.includes('LE_SET')) {
          return 'Reported Latest Estimate Fig';
        } else if (item.trim() === 'Tanso') {
          return 'Approved SET Snapshots';
        } else if (item.trim() === 'Tanso_Future') {
          return 'Booked_Futures';
        } else if (item.trim() === POWER_INTENSITY_MODEL) {
          return `${POWER_INTENSITY_MODEL}${
            !isPowerIntensityModelAndTanso ? ',Approved SET Snapshots' : ''
          }`;
        }
        return item;
      }),
    ),
  );
  const transformSource = updatedData.flatMap((item) => item.split(',')).map((item) => item.trim());
  return transformSource.join(', ');
};

export const mergeAndSumPipelineGas = (processedCombinedArrayWithKey: SummedValues[]) => {
  const pipelineGasEntries = processedCombinedArrayWithKey.filter(
    (item) => item.name === pipeLineGas,
  );

  if (pipelineGasEntries.length < 2) {
    return processedCombinedArrayWithKey;
  }

  const mergedPipelineGasMap = new Map<string, ProcessedDataItem>();

  const addToMap = (entry: ProcessedDataItem) => {
    if (mergedPipelineGasMap.has(entry.year)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const existingEntry = mergedPipelineGasMap.get(entry.year)!;
      existingEntry.value += entry.value;
    } else {
      mergedPipelineGasMap.set(entry.year, { ...entry });
    }
  };

  pipelineGasEntries.forEach((item) => item.data.forEach(addToMap));

  const mergedPipelineGasData = Array.from(mergedPipelineGasMap.values());

  return processedCombinedArrayWithKey
    .filter((item) => item.name !== pipeLineGas)
    .concat([{ name: pipeLineGas, data: mergedPipelineGasData }]);
};
