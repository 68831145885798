import { CHART_AXIS_STYLE, commodityOrder, fiveHundred } from '../../../constants';
import { ITransformDataObject, PointsData } from '../../../Types/types';
import { ShellFont, toolTipFormatter } from '../../../utils';
import Highcharts from 'highcharts';

export const contractedVsPlanVsLeHighchart = (
  groupedSeriesData: ITransformDataObject[],
  categoriesData: string[],
  tickInterval: number | undefined,
  isToggle: boolean,
) => {
  groupedSeriesData.sort((a, b) => {
    return commodityOrder.indexOf(a.name) - commodityOrder.indexOf(b.name);
  });
  const options: Highcharts.Options = {
    chart: {
      type: 'column',
      height: fiveHundred,
      marginBottom: 100,
      style: {
        fontFamily: 'Shell Font, Arial, sans-serif', // Set 'Shell Font' as the primary font family for the whole chart
      },
    },
    title: {
      text: 'Contracted vs Plan vs LE',
      align: 'left',
      y: 20,
    },
    subtitle: {
      align: 'left',
      text: `An overview of ${
        isToggle ? 'Sales Volume' : 'Net Absolute Emissions'
      } based on the latest estimate of<br /> contracted sales compared to the business plan forecast.`,
      style: {
        fontSize: '14px',
        fontFamily: ShellFont,
      },
    },
    legend: {
      itemStyle: {
        fontSize: '12px',
        fontWeight: 'light',
        color: '#757575',
        fontFamily: 'Shell Font, Arial, sans-serif',
      },
    },
    yAxis: {
      title: {
        useHTML: true,
        text: `${isToggle ? 'Volume (TW<sub>h</sub>)' : 'Carbon Emission (MTPA)'}`,
        style: CHART_AXIS_STYLE,
      },
      tickInterval,
    },
    xAxis: {
      title: {
        text: 'Year',
        style: CHART_AXIS_STYLE,
      },
      categories: categoriesData,
      plotLines: [
        {
          dashStyle: 'Dash',
          color: 'rgba(117, 117, 117, 117)',
          width: 1.5,
          value: 0,
          zIndex: 10,
        },
      ],
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
      valueDecimals: 2,
      formatter() {
        return toolTipFormatter(this as unknown as PointsData);
      },
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: false,
        },
        pointWidth: 35,
        borderColor: 'transparent',
        borderRadius: 0,
      },
      line: {
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 5,
        },
      },
      area: {
        marker: {
          lineColor: '#008557',
          lineWidth: 8,
          symbol: 'square',
          radius: 2,
        },
        fillOpacity: 0,
        lineWidth: 0,
      },
    },
    series: groupedSeriesData.map((data) => ({
      ...data,
      stacking: data.name === 'Total Plan' ? undefined : 'normal',
    })) as unknown as Highcharts.SeriesAreaOptions[],
  };
  return options;
};
