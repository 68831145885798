import React, { useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import {
  FOOT_NOTES_CARBON_INTENSITY,
  PARIG_FINANCE_REPORTING,
  PLANNING_ONE,
  POWER_INTENSITY_MODEL,
  POWER_INTENSITY_MODEL_RES,
  REPORTONE,
  TANSO,
} from '../../../api/constants';
import { StyledDiv } from './CarbonIntensityChart.styles';
import {
  StackedVerticalBarChartProps,
  ProcessedDataItem,
  KeyValueData,
  YearProcessedDataItem,
  GroupedDataItem,
  SummedValues,
  TargetDataItem,
  TradeData,
} from './types';
import Chart from '../../Chart/Chart';
import { GAS_DISCLAIMER, POWER_DISCLAIMER, ten } from '../../../constants';
import { processAndSortData } from './utils';
import { highchartOptions } from './highchartOptions';
import { mergeActualAndPlanCarbonIntensity } from '../../../utils';
import { currentYear } from '../../../utils/utils';

declare module 'highcharts' {
  interface PointOptionsObject {
    tooltipText?: string;
  }
}
const planningSources = new Set([PLANNING_ONE, PARIG_FINANCE_REPORTING]);
const actuals = 'actuals';
const line = 'line';
const target = 'Target';
// Historical Data
const historicalSources = new Set([
  TANSO,
  REPORTONE,
  POWER_INTENSITY_MODEL,
  POWER_INTENSITY_MODEL_RES,
]);

const CarbonIntensityChart: React.FC<StackedVerticalBarChartProps> = ({
  commodityFilteredValue,
  carIntTargetPerRegion,
  commodityVal,
}) => {
  const commodityNewFilteredValue = commodityFilteredValue;
  const uniqueKPIsSet = new Set(commodityNewFilteredValue.map((item) => item.KPI));
  const uniqueKPIs = Array.from(uniqueKPIsSet);

  const sourcesSet = new Set(commodityNewFilteredValue.map((item) => item.SOURCE));
  const uniqueSources = Array.from(sourcesSet);

  const processSortKPI = (kpiSet: Set<string>, key: keyof TradeData) => {
    return processAndSortData(
      commodityNewFilteredValue,
      historicalSources,
      planningSources,
      kpiSet,
      key,
    );
  };

  // Create KPI sets dynamically based on uniqueKPIs
  // Process and sort data for each KPI set
  const processedQuantityDataByKPI: KeyValueData[] = uniqueKPIs.map((kpiSet) => ({
    key: kpiSet,

    value: processSortKPI(new Set([kpiSet]), 'QUANTITY'),
  }));

  const processedIntensityDataByKPI: KeyValueData[] = uniqueKPIs.map((kpiSet) => ({
    key: kpiSet,

    value: processSortKPI(new Set([kpiSet]), 'CARBON_EMISSION'),
  }));

  let combinedQuantityArrayWithKey: { name: string; data: ProcessedDataItem[] }[] = [];

  combinedQuantityArrayWithKey = processedQuantityDataByKPI.map((dataObj) => {
    return {
      name: dataObj.key,
      data: dataObj.value,
    };
  });

  let combinedIntensityArrayWithKey: { name: string; data: ProcessedDataItem[] }[] = [];

  combinedIntensityArrayWithKey = processedIntensityDataByKPI.map((dataObj) => {
    return {
      name: dataObj.key,
      data: dataObj.value,
    };
  });

  const combinedDataArray: ProcessedDataItem[] = processedQuantityDataByKPI.flatMap(
    (dataObj) => dataObj.value,
  );
  // Remove the "value" field from each object
  const reducedArray = combinedDataArray.map(({ year, type }) => ({ year, type }));
  const yearTypeSet = new Set(reducedArray.map(({ year, type }) => `${year}-${type}`));

  // Convert Set back to an array
  const uniqueYearTypeArray = Array.from(yearTypeSet);

  // Sort the array first by "actuals" and then by "plan" within each year
  uniqueYearTypeArray.sort((a, b) => {
    const [yearA, typeA] = a.split('-');
    const [yearB, typeB] = b.split('-');

    if (typeA === actuals && typeB !== actuals) {
      return -1; // "actuals" comes before "plan"
    }
    if (typeA !== actuals && typeB === actuals) {
      return 1; // "actuals" comes before "plan"
    }
    // If both are "actuals" or both are "plan", sort by year
    return yearA.localeCompare(yearB);
  });
  // Convert the sorted array back to an array of objects
  const resultYearArray = uniqueYearTypeArray.map((key) => {
    const [year, type] = key.split('-');
    return {
      year,
      type,
    };
  });

  // Create an array of values with matching years and types
  const mapMissingData = (
    data: YearProcessedDataItem[],
    processedData: ProcessedDataItem[],
  ): ProcessedDataItem[] => {
    return data.map(({ year, type }) => {
      const matchingItem = processedData?.find((item) => item.year === year && item.type === type);
      return matchingItem ?? { type, value: 0, year };
    });
  };

  const groupData = (data: ProcessedDataItem[]): GroupedDataItem[] => {
    const groupedData: { [key: string]: GroupedDataItem } = {};

    data?.forEach((item) => {
      const { year, type, value } = item;
      const key = `${year}-${type}`;

      if (!groupedData[key]) {
        groupedData[key] = { year, type, total_value: 0 };
      }

      groupedData[key].total_value += value;
    });

    return Object.values(groupedData);
  };
  // Process the data for each array in combinedArrayWithKey
  const processedQuantityArrayWithKey: SummedValues[] = combinedQuantityArrayWithKey.map(
    (dataObj) => ({
      name: dataObj.name,
      data: mapMissingData(resultYearArray, dataObj.data),
    }),
  );

  const combinedprocessedQuantityArrayWithKey: ProcessedDataItem[] =
    processedQuantityArrayWithKey.flatMap((dataObj) => dataObj.data);
  const groupedprocessedQuantityArrayWithKey = groupData(combinedprocessedQuantityArrayWithKey);
  const processedIntensityArrayWithKey = combinedIntensityArrayWithKey.map((dataObj) => ({
    name: dataObj.name,
    data: mapMissingData(resultYearArray, dataObj.data),
  }));

  const combinedprocessedIntensityArrayWithKey: ProcessedDataItem[] =
    processedIntensityArrayWithKey.flatMap((dataObj) => dataObj.data);

  const groupedprocessedIntensitArrayWithKey = groupData(combinedprocessedIntensityArrayWithKey);

  // Create a map of total values by year and type from the second array
  const totalValuesMap: Record<string, number> = {};
  groupedprocessedQuantityArrayWithKey.forEach((item) => {
    const key = `${item.year}-${item.type}`;
    totalValuesMap[key] = item.total_value;
  });

  // Divide total_value in array1 by the corresponding total_value in array2
  const resultArray: GroupedDataItem[] = groupedprocessedIntensitArrayWithKey.map((item) => {
    const key = `${item.year}-${item.type}`;
    const divisor = totalValuesMap[key];
    // Calculate the division result, ensuring divisor is not zero
    const dividedValue = divisor !== 0 ? item.total_value / divisor : 0;

    return {
      ...item,
      total_value: dividedValue,
    };
  });

  const targetYears = resultYearArray ? resultYearArray.map((item) => item.year) : ['2030'];
  const carIntTargetNewPerRegion: GroupedDataItem[] = carIntTargetPerRegion || [
    { year: '0', type: target, total_value: 0 },
  ];

  const resultArraycarIntTargetNewPerRegion: TargetDataItem[] = targetYears.map((year) => {
    const foundData = carIntTargetNewPerRegion.find((data) => data.year === year);

    return (
      foundData ?? {
        total_value: null,
        type: target,
        year,
      }
    );
  });

  function findFirstNotNullIndex(data: TargetDataItem[]): number | null {
    for (let i = 0; i < data?.length; i++) {
      if (data[i].total_value !== null) {
        return i;
      }
    }
    return null; // If no non-null "total_value" is found
  }
  const region = carIntTargetPerRegion[0]?.region
    ?.toLocaleLowerCase()
    .replace(/^\w/, (c) => c.toUpperCase());
  const newarray =
    commodityVal === 'Power'
      ? [
          { name: 'Carbon Intensity', data: resultArray },
          {
            type: line,
            color: '#eb8705',
            name: `Target Region (${region})`,
            data: resultArraycarIntTargetNewPerRegion,
            marker: {
              symbol: 'square',
              lineWidth: 8,
              lineColor: '#eb8705',
            },
          },
        ]
      : [{ name: 'Carbon Intensity', data: resultArray }];
  const newData = mergeActualAndPlanCarbonIntensity(newarray);
  const firstNotNullIndex =
    findFirstNotNullIndex(newData.filter((item) => item.name.includes('Target Region'))[0]?.data) ??
    ten;
  const indexOfLastActuals = resultYearArray
    .slice()
    .reverse()
    .findIndex((item) => item.type === actuals);
  const options = highchartOptions({
    commodityVal,
    resultYearArray: resultYearArray.filter(
      (item) => !(item.type === actuals && item.year === String(currentYear)),
    ),
    firstNotNullIndex,
    indexOfLastActuals,
    newarray: newData,
  });
  // Step 1: State variable to keep track of the key
  const [chartKey, setChartKey] = useState(0);

  // Step 2: Function to update the key when data changes or component re-renders
  const refreshChart = () => {
    // You can update the key with a new value here
    setChartKey((prevKey) => prevKey + 1);
  };
  const prevCommodityFilteredValue = useRef<string | null>(null);
  useEffect(() => {
    const currentStringified = JSON.stringify(commodityFilteredValue);
    if (currentStringified !== prevCommodityFilteredValue.current) {
      refreshChart();
      prevCommodityFilteredValue.current = currentStringified;
    }
  }, [commodityFilteredValue]);
  const disclaimer = commodityVal === 'Power' ? POWER_DISCLAIMER : GAS_DISCLAIMER;
  return (
    <StyledDiv>
      <Chart
        key={chartKey}
        chartKey={`${commodityVal}CarbonIntensity`}
        highcharts={Highcharts}
        options={options}
        source={uniqueSources.join(', ')}
        footerNote={FOOT_NOTES_CARBON_INTENSITY}
        disclaimer={[disclaimer]}
      />
    </StyledDiv>
  );
};

export default React.memo(CarbonIntensityChart);
