export const THREE_P_RENEWABLE_POWER_SUPPLY_PPA = '3P Renewable Power Supply (PPA)';
export const RENEWABLE_ATTRIBUTES_REC = 'Renewable Attributes REC';
export const EQUITY_RENEWABLE_POWER_GENERATION = 'Equity Renewable Power Generation';
export const THREE_P_POWER_SUPPLY_MARKET_POOL = '3P Power Supply (Market Pool)';
export const EQUITY_GAS_FIRED_POWER_GENERATION = 'Equity Gas-Fired Power Generation';
export const THREE_P_GAS_FIRED_POWER_SUPPLY_PPA = '3P Gas-Fired Power Supply (PPA)';
export const EXTERNAL_POWER_SALES_RENEWABLE_CPPA = 'External Power Sales (Renewable CPPA)';
export const EXTERNAL_POWER_SALES_GF = 'External Power Sales (GF Generation)';
export const EXTERNAL_POWER_SALES_RENEWABLE_REC = 'External Power Sales (REC)';
export const EXTERNAL_POWER_SALES_RESIDUAL_GRID = 'External Power Sales (Grid)';
export const EXTERNAL_POWER_SALES_RENEWABLE_DF = 'External Power Sales (DF Generation)';

export const EXTERNAL_GAS_SALES_VOLUMES_RENEWABLE = 'External Gas Sales Volumes (Renewable)';
export const EXTERNAL_GAS_SALES_VOLUMES_PIPELINE = 'External Gas Sales Volumes (Pipeline)';
export const INLAND_SALES_VOLUME_NATURAL_GAS = 'Inland Sales Volumes - Natural Gas';
export const INLAND_SALES_VOLUME_PIPELINE_GAS = 'Inland Sales Volumes - Pipeline Gas';
export const PLANNING_ONE = 'PlanningOne';
export const TANSO = 'Tanso';
export const REPORTONE = 'ReportOne';
export const POWER_INTENSITY_MODEL = 'PowerIntensityModel';
export const PARIG_FINANCE_REPORTING = 'PARIGFinance&Reporting';
export const TANSO_FUTURE = 'Tanso_Future';
export const CARBON_KPI_CALCULATOR = 'Carbon KPI Calculator';
export const LE_SE = 'LE_SE';
export const LE_SET = 'LE_SET';
export const POWER_INTENSITY_MODEL_RES = 'PowerIntensityModelRES';
export const COUNTRY_MULTIPLE = 'Multiple';
export const COUNTRY_ASIA_MULTIPLE = 'Asia Pacific - Multiple';
export const COUNTRY_EUROPE_MULTIPLE = 'Europe - Multiple';
export const POWER = 'Power';
export const NATURAL_GAS = 'Natural Gas';
export const HYDROGEN = 'Hydrogen';
export const RENEWABLE = 'Renewable';
export const NONRENEWABLE = 'Non-Renewable';
export const RESIDUAL = 'Residual';
export const RESIDUAL_GRID = 'Residual Grid';
export const FEEDBACK_LINK = 'https://forms.office.com/e/kwsN2g8rPe';
export const QUANTITY = 'QUANTITY';
export const CARBON_EMISSION = 'CARBON_EMISSION';
export const SE_GLOBAL_SBTI = 'SE GLOBAL (SBTI)';
export const FOOT_NOTES_CARBON_INTENSITY = 'Carbon targets are unavailable for Africa and Global';
export const LNG = 'LNG';
export const LNG_TERM_EQUITY_SALES_VOLUMES = 'LNG Term Equity Sales Volumes';
export const LNG_TERM_THIRD_PARTY_SALES_VOLUMES = 'LNG Term 3rd Party Sales Volumes';
export const LNG_TERM_JV_SALES_VOLUMES = 'LNG Term JV Sales Volumes';
export const LNG_SPOT_SALES_VOLUMES = 'LNG Spot Sales Volumes';
export const NATURAL_GAS_SALES_VOLUMES = 'Inland Sales Volumes - Natural Gas';
export const VIEW_DETAILS = 'View Details';
export const LOWER_THAN_PLANNED = 'Lower than Plan';
export const HIGHER_THAN_PLANNED = 'Higher than Plan';
export const POSITIVE_DELTA = 'Positive Delta';
export const NEGATIVE_DELTA = 'Negative Delta';
export const ALL_REGIONS = 'All Regions';
export const UNITED_STATES = 'United States';
export const ALL_COUNTRIES = 'All Countries';

export const CLICK_HERE = 'CLICK HERE';
export const REQUEST_ACCESS_MESSAGE =
  'to request for access and select "SE & IGRES Carbon Portfolio Management"  in the application dropdown.';
export const SOM_MAIL_ID = 'DST&SApplicationSupport@shell.com';
