import {
  ChartData,
  ChartInsideData,
  DataEntry,
  GroupedData,
  IGroupNCI,
  ITradeData,
  ITransformDataObject,
  IYearDataRegion,
  IYearTypeData,
  PlannedVsCommitted,
  PointsData,
  RegionDataObject,
  YearTypeDataValue,
} from '../Types/types';
import {
  TANSO,
  REPORTONE,
  POWER_INTENSITY_MODEL,
  POWER_INTENSITY_MODEL_RES,
  PLANNING_ONE,
  PARIG_FINANCE_REPORTING,
  TANSO_FUTURE,
  CARBON_KPI_CALCULATOR,
  LE_SE,
  LE_SET,
} from '../api/constants';
import {
  PLANNING_ONE_SOURCE,
  emailList,
  power,
  naturalGas,
  ten,
  zero,
  two,
  four,
  one,
  six,
  pipeLineGas,
  CarbonBudget,
  quarters,
  renewableGas,
} from '../constants';
import Highcharts from 'highcharts';
import { currentYear } from './utils';
import { convertValue } from '../components/ScenerioModelling/conversionutils';
import { ProcessedDataItem } from '../components/charts/VolumeCarbonEmissionChart/types';
import { GroupedDataItem, TargetDataItem } from '../components/charts/CarbonIntensityChart/types';
const oneSix = 16;

export function roundNumberStringToDecimalPlaces(
  value: string | undefined | null,
  decimalPlaces: number,
): string | undefined {
  if (value === undefined || value === null) {
    return undefined;
  }

  const parsedValue = parseFloat(value);

  if (isNaN(parsedValue)) {
    return value; // Return the original value if it's not a valid number
  }

  const roundedValue =
    Math.round(parsedValue * Math.pow(ten, decimalPlaces)) / Math.pow(ten, decimalPlaces);
  return roundedValue.toFixed(decimalPlaces);
}

export function roundNumberStringToNoDecimal(value: string | undefined | null): string | undefined {
  if (value === undefined || value === null) {
    return undefined;
  }

  const parsedValue = parseFloat(value);

  if (isNaN(parsedValue)) {
    return value; // Return the original value if it's not a valid number
  }

  const roundedValue = Math.round(parsedValue);
  return roundedValue.toString();
}

export function roundNumber(num: number, decimalPlaces: number): number {
  const multiplier = Math.pow(ten, decimalPlaces);
  return Math.round(num * multiplier) / multiplier;
}

export function commaSeparatedKPI(uniqueKPIs: string[]): string {
  let commaSeparatedString = '';

  if (uniqueKPIs.length === one) {
    commaSeparatedString = uniqueKPIs[zero];
  } else if (uniqueKPIs.length > one) {
    for (let i = zero; i < uniqueKPIs.length - one; i++) {
      commaSeparatedString += uniqueKPIs[i] + ', ';
    }
    commaSeparatedString += 'and ' + uniqueKPIs[uniqueKPIs.length - 1];
  }

  return commaSeparatedString;
}

export function carbonColor(value: number): string {
  const nineFive = 95;
  const nineNine = 99;
  if (value < nineFive) {
    return '#008000'; // Green color
  } else if (value >= nineFive && value <= nineNine) {
    return '#FFBF00'; // Yellow color
  } else {
    return '#ff0000'; // Red
  }
}

export function hexToRgb(hexCode: string) {
  // Remove the "#" symbol (if present)
  const hex = hexCode.replace('#', '');

  // Convert hex to RGB
  const r = parseInt(hex.substring(zero, two), oneSix);
  const g = parseInt(hex.substring(two, four), oneSix);
  const b = parseInt(hex.substring(four, six), oneSix);

  return { r, g, b };
}

export function lightenColor(hexCode: string, percent: number) {
  const { r, g, b } = hexToRgb(hexCode);

  // Apply percentage of white to lighten the color
  const twoDoubleFive = 255;
  const oneDoubleZero = 100;
  const rLightened = Math.min(twoDoubleFive, r + ((twoDoubleFive - r) * percent) / oneDoubleZero);
  const gLightened = Math.min(twoDoubleFive, g + ((twoDoubleFive - g) * percent) / oneDoubleZero);
  const bLightened = Math.min(twoDoubleFive, b + ((twoDoubleFive - b) * percent) / oneDoubleZero);

  // Convert the lightened RGB values back to hex
  const rHexNumber = Math.round(rLightened).toString(oneSix).padStart(two, '0');
  const gHexNumber = Math.round(gLightened).toString(oneSix).padStart(two, '0');
  const bHexNumber = Math.round(bLightened).toString(oneSix).padStart(two, '0');
  return `#${rHexNumber}${gHexNumber}${bHexNumber}`;
}

// Function to check if the given email matches either of the specified emails
export function isEmailMatch(email: string) {
  return emailList?.includes(email);
}
interface DateTimeObject {
  year: string;
  month: string;
  day: string;
  hours: string;
  minutes: string;
  seconds: string;
}

export function extractdateTime(date: Date): DateTimeObject {
  const year = date.getFullYear().toString().padStart(four, '0');
  const month = (date.getMonth() + 1).toString().padStart(two, '0');
  const day = date.getDate().toString().padStart(two, '0');
  const hours = date.getHours().toString().padStart(two, '0');
  const minutes = date.getMinutes().toString().padStart(two, '0');
  const seconds = date.getSeconds().toString().padStart(two, '0');
  return { year, month, day, hours, minutes, seconds };
}

export function formatDateTime(date: Date): string {
  const { year, month, day, hours, minutes, seconds } = extractdateTime(date);
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function formatDateTimeDowloadFile(date: Date): string {
  const { year, month, day, hours, minutes, seconds } = extractdateTime(date);
  return `${year}-${month}-${day}_${hours}${minutes}${seconds}`;
}

// Calculation for Emission By SE_IPU_Region
export const calculateEmissionsBySeIpuRegion = (
  ipudata: ITradeData[],
): {
  commodity: string;
  seIpuRegion: string;
  plan: number;
  delta: number;
  latest_estimate: number;
}[] => {
  const emissionsBySeIpuRegion: {
    [key: string]: {
      commodity: string;
      seIpuRegion: string;
      plan: number;
      latest_estimate: number;
    };
  } = {};
  for (const item of ipudata) {
    const { TRADE_COMMODITY_NAME, SE_IPU_Region, SOURCE, QUANTITY } = item;
    const key = `${SE_IPU_Region}|${TRADE_COMMODITY_NAME}`;
    if (!(key in emissionsBySeIpuRegion)) {
      emissionsBySeIpuRegion[key] = { commodity: '', plan: 0, seIpuRegion: '', latest_estimate: 0 };
    }

    if (SOURCE === PLANNING_ONE_SOURCE) {
      emissionsBySeIpuRegion[key].plan += QUANTITY;
    } else {
      emissionsBySeIpuRegion[key].latest_estimate += QUANTITY;
    }
    emissionsBySeIpuRegion[key].commodity = TRADE_COMMODITY_NAME;
    emissionsBySeIpuRegion[key].seIpuRegion = SE_IPU_Region;
  }
  return Object.entries(emissionsBySeIpuRegion).map(
    ([, { commodity, seIpuRegion, plan, latest_estimate }]) => {
      const delta = latest_estimate - plan;
      return { commodity, seIpuRegion, plan, delta, latest_estimate };
    },
  );
};

// Calculation for Emission By Trade Commodity
export const calculateEmissionsByTradeCommodityName = (
  tradedata: ITradeData[],
): {
  TRADE_COMMODITY_NAME: string;
  plan: number;
  delta: number;
  latest_estimate: number;
  source: string;
}[] => {
  const emissionsByTradeCommodityName: {
    [tradeCommodityName: string]: { plan: number; latest_estimate: number; source: string };
  } = {};

  for (const item of tradedata) {
    const { TRADE_COMMODITY_NAME, SOURCE, CARBON_EMISSION } = item;

    if (!(TRADE_COMMODITY_NAME in emissionsByTradeCommodityName)) {
      emissionsByTradeCommodityName[TRADE_COMMODITY_NAME] = {
        plan: 0,
        latest_estimate: 0,
        source: '',
      };
    }

    if (SOURCE === PLANNING_ONE_SOURCE) {
      emissionsByTradeCommodityName[TRADE_COMMODITY_NAME].plan += CARBON_EMISSION;
    } else {
      emissionsByTradeCommodityName[TRADE_COMMODITY_NAME].latest_estimate += CARBON_EMISSION;
    }
    emissionsByTradeCommodityName[TRADE_COMMODITY_NAME].source = SOURCE;
  }
  const resultArray: {
    TRADE_COMMODITY_NAME: string;
    plan: number;
    delta: number;
    latest_estimate: number;
    source: string;
  }[] = [];
  for (const tradeCommodityName in emissionsByTradeCommodityName) {
    if (tradeCommodityName in emissionsByTradeCommodityName) {
      const { plan, latest_estimate, source } = emissionsByTradeCommodityName[tradeCommodityName];
      const changedTradeCommodityName =
        tradeCommodityName === naturalGas ? pipeLineGas : tradeCommodityName;
      const delta = plan - latest_estimate;
      resultArray.push({
        TRADE_COMMODITY_NAME: changedTradeCommodityName,
        plan,
        delta,
        latest_estimate,
        source,
      });
    }
  }
  return resultArray;
};

export const ChartLegendStyle = {
  fontSize: '12px', // Adjust the font size here as per your requirement
  fontWeight: 'light',
  color: '#757575',
  fontFamily: 'Shell Font, Arial, sans-serif',
};

export const ChartAxisStyle = {
  fontFamily: 'Shell Font, Verdana, sans-serif',
  fontSize: '11px',
  fontWeight: '600',
  lineHeight: '16px',
  letterSpacing: '0em',
  textAlign: 'right',
  color: '#343434',
};

export const ShellFont = 'Shell Font, Verdana, sans-serif';

export const uptoTwoDecimal = (value: number) => {
  return value === 0 ? '-' : value?.toFixed(two);
};
export const colorHexCode = (name: string) => {
  switch (name) {
    case 'Hydrogen':
      return '#9A60A4';
    case 'Pipeline Gas':
      return '#FFC600';
    case 'Pipeline Gas (Deal)':
      return '#FFF7B4';
    case 'Natural Gas':
      return '#FFC600';
    case 'Power':
      return '#336094';
    case 'Power (Deal)':
      return '#6E94C0';
    case 'LNG':
      return '#8FB753';
    case 'Total Plan':
      return '#D93638';
    case 'Carbon Budget':
      return '#D93638';
    case 'Latest Estimate (LE)':
      return '#008557';
    // Add more cases for other names if needed
    default:
      return '#9A60A4'; // Default color if name doesn't match
  }
};
const convertZeroToNull = (value: number | undefined) => (value === 0 ? null : value);

const dataY = (type: string, dataPoint: ChartInsideData, showEmissionChart: boolean) => {
  const { CARBON_EMISSION, QUANTITY, ORIGINAL_CARBON_EMISSION } = dataPoint;
  if (type === 'area') {
    return !showEmissionChart ? convertZeroToNull(CARBON_EMISSION) : convertZeroToNull(QUANTITY);
  } else {
    return convertZeroToNull(ORIGINAL_CARBON_EMISSION);
  }
};

export const typeBasedChart = (chartData: ChartData, showEmissionChart: boolean) => {
  const { type, name, data, color, dashStyle } = chartData;
  const shouldNotMapData = !(type === 'line' && showEmissionChart === true) && data?.length > 0;

  const transformedData = shouldNotMapData
    ? data.map((dataPoint: ChartInsideData) => ({
        y: dataY(type, dataPoint, showEmissionChart),
        tooltipText: `${dataPoint.type === 'historical' ? '(History)' : ''}`,
      }))
    : [];
  return {
    type,
    color: type === 'area' ? colorHexCode(name) : color,
    name,
    dashStyle,
    data: transformedData,
  } as Highcharts.SeriesAreaOptions;
};

const sortTradeDataByYear = (tradeData: PlannedVsCommitted[]) => {
  return [...tradeData].sort((a, b) => a.TRADE_YEAR - b.TRADE_YEAR);
};

// Historical Data
const historicalSources = [TANSO, REPORTONE, POWER_INTENSITY_MODEL, POWER_INTENSITY_MODEL_RES];
const planningSources = [
  PLANNING_ONE,
  PARIG_FINANCE_REPORTING,
  TANSO_FUTURE,
  CARBON_KPI_CALCULATOR,
  LE_SE,
  LE_SET,
];

const determineYearType = (source: string, TRADE_YEAR: number): string | undefined => {
  const sources = source.split(', '); // Split the string into individual sources

  // Check each source against historicalAndPlanningSources
  for (const src of sources) {
    if (planningSources.includes(src)) {
      return `${TRADE_YEAR}-plan`;
    } else if (historicalSources.includes(src)) {
      return `${TRADE_YEAR}-historical`;
    }
  }

  // Return undefined if no match is found
  return undefined;
};

/* Function to map CarbonBudgetLine to YearTypeData */
const mapToYearTypeData = (line: PlannedVsCommitted): IYearTypeData => {
  const { SOURCE, TRADE_YEAR, TRADE_COMMODITY_NAME, CARBON_BUDGET, CARBON_EMISSION, QUANTITY } =
    line;
  const yearType = SOURCE && determineYearType(SOURCE, TRADE_YEAR);
  return {
    yearType,
    TRADE_YEAR,
    TRADE_COMMODITY_NAME,
    CARBON_BUDGET,
    CARBON_EMISSION,
    QUANTITY,
    SOURCE,
    year: TRADE_YEAR,
    type: yearType?.includes('historical') ? 'historical' : 'plan',
  };
};
const addKpiSource = (kpiMovedDeals: PlannedVsCommitted[]) => {
  return kpiMovedDeals.map((item) => ({
    ...item,
    SOURCE: CARBON_KPI_CALCULATOR,
  }));
};

const splitCommittedVolume = (
  committedVolumeData: IYearTypeData[],
  toggleType: keyof IYearTypeData,
) => {
  const committedGroupedData: { [key: string]: IYearTypeData[] } = committedVolumeData.reduce(
    (acc, curr) => {
      const commodityName: string = curr.TRADE_COMMODITY_NAME;
      const yearType = curr.yearType;
      if (yearType) {
        if (!acc[commodityName]) {
          acc[commodityName] = [];
        }
        const existingDataWithSameYearTypeIndex = acc[commodityName].findIndex(
          (entry) => entry.yearType === yearType,
        );
        if (existingDataWithSameYearTypeIndex === -1) {
          acc[commodityName].push(curr);
        } else if (curr[toggleType]) {
          const existingData = acc[commodityName][existingDataWithSameYearTypeIndex];
          existingData[toggleType] =
            ((existingData[toggleType] as number) ?? 0) + ((curr[toggleType] as number) ?? 0);
        }
      }
      return acc;
    },
    {} as { [key: string]: IYearTypeData[] },
  );
  const resultArray = [];
  for (const [name, data] of Object.entries(committedGroupedData)) {
    resultArray.push({ type: 'column', dashStyle: 'Solid', name, data });
  }
  return resultArray.reverse();
};
export const plannedVsEstimatedSeriesData = (
  plannedVolume: PlannedVsCommitted[],
  committedVolume: PlannedVsCommitted[],
  kpiMovedDeals: PlannedVsCommitted[],
  isToggle: boolean,
) => {
  const toggleType = isToggle ? 'QUANTITY' : 'CARBON_EMISSION';
  const updatedCommodityName = addKpiSource(kpiMovedDeals);
  const LeData = committedVolume.filter(
    (item) => [LE_SE, LE_SET].includes(item.SOURCE) && item.TRADE_YEAR >= currentYear,
  );
  const summedLeseAndLeset = LeData?.reduce(
    (acc, item) => {
      return {
        ...item,
        CARBON_EMISSION: acc.CARBON_EMISSION + item.CARBON_EMISSION,
        QUANTITY: acc.QUANTITY + item.QUANTITY,
        SOURCE: `${LE_SE}, ${LE_SET}`,
      };
    },
    { CARBON_EMISSION: 0, QUANTITY: 0 },
  );
  committedVolume = committedVolume.filter((item) => item.SOURCE === TANSO_FUTURE);
  plannedVolume = sortTradeDataByYear(plannedVolume);
  committedVolume = sortTradeDataByYear(committedVolume);
  kpiMovedDeals = sortTradeDataByYear(updatedCommodityName);
  const combineYearTypeForPlannedVolume = plannedVolume.map(mapToYearTypeData);
  const combineYearTypeForCommittedVolume = committedVolume.map(mapToYearTypeData);
  const combinedYearTypeForKpiMovedDeals = kpiMovedDeals.map(mapToYearTypeData);
  const combinedYearTypeForLeData = [summedLeseAndLeset as PlannedVsCommitted].map(
    mapToYearTypeData,
  );
  const combinedAllData = [
    ...combineYearTypeForPlannedVolume,
    ...combineYearTypeForCommittedVolume,
    ...combinedYearTypeForKpiMovedDeals,
    ...combinedYearTypeForLeData,
  ];
  const uniqueSources = [...new Set(combinedAllData?.map((item) => item.SOURCE))];
  const uniqueYears = [
    ...new Set(
      combinedAllData
        .filter((item) => !(item.year < currentYear && item.type === 'plan'))
        .map((item) => `${item.year}-${item.type}`),
    ),
  ]
    .map((uniqueYear) => {
      const [year, type] = uniqueYear.split('-');
      return { year: Number(year), type };
    })
    .sort((a, b) => {
      if (a.type !== b.type) {
        return a.type.localeCompare(b.type);
      } else {
        return a.year - b.year;
      }
    });

  const planned: GroupedData = {
    type: 'line',
    name: 'Total Plan',
    color: '#D93638',
    dashStyle: 'Dash',
    data: combineYearTypeForPlannedVolume || [],
  };
  const latestEstimate = {
    type: 'area',
    name: 'Latest Estimate (LE)',
    color: '#008557',
    dashStyle: 'Solid',
    data: combinedYearTypeForLeData ?? [],
  };
  const committed = splitCommittedVolume(
    [...combineYearTypeForCommittedVolume, ...combinedYearTypeForKpiMovedDeals],
    toggleType,
  );
  const isLeDataAvailable =
    summedLeseAndLeset.CARBON_EMISSION > 0 || summedLeseAndLeset.QUANTITY > 0;
  const groupedData = [...(isLeDataAvailable ? [latestEstimate] : []), ...committed, planned];
  const groupedDataWithNull = addNullvalueForMissingYear(groupedData);
  const transformedDataGroupData = transformGroupedData(groupedDataWithNull, toggleType);
  return { transformedDataGroupData, uniqueYears, uniqueSources };
};

const filterValidData = (data: YearTypeDataValue[]) => {
  return data.filter((item): item is number | null => typeof item === 'number' || item === null);
};
const transformGroupedData = (
  groupedSeriesData: GroupedData[],
  toggleType: keyof IYearTypeData,
) => {
  let seriesData = groupedSeriesData.flatMap((data) => {
    const color = colorHexCode(data.name);

    const actualData = data.data
      .filter((item) => item.type === 'historical')
      .map((item) => ({ ...data, color, data: [item[toggleType]] }));

    const planData = data.data
      .filter((item) => item.type === 'plan' && item.TRADE_YEAR >= currentYear)
      .map((item) => ({
        ...data,
        color,
        data: data.name === CarbonBudget ? [item.CARBON_BUDGET] : [item[toggleType]],
      }));

    return [...actualData, ...planData];
  });

  seriesData = seriesData.reduce((accumulator, series) => {
    const existingSeriesIndex = accumulator.findIndex((item) => item.name === series.name);
    if (existingSeriesIndex !== -1) {
      // If series with the same name already exists, append the data to its existing data array
      accumulator[existingSeriesIndex].data = accumulator[existingSeriesIndex].data.concat(
        filterValidData(series.data),
      );
    } else {
      // If series with the same name doesn't exist, create a new series object
      accumulator.push({
        ...series,
        data: filterValidData(series.data),
      });
    }
    return accumulator;
  }, [] as ITransformDataObject[]);
  return seriesData;
};
const addNullvalueForMissingYear = (groupedData: GroupedData[]) => {
  let maxLength = 0;
  let maxIndex = -1;

  // Find the maximum length of the data array among all objects and its index
  groupedData.forEach((obj, index) => {
    if (obj.data.length > maxLength) {
      maxLength = obj.data.length;
      maxIndex = index;
    }
  });

  const maxLengthData = groupedData[maxIndex];
  groupedData.forEach((gData, index) => {
    if (gData.data.length !== maxLength) {
      groupedData[index] = getUpdateData(gData, maxLengthData);
    }
  });
  return groupedData;
};
const getUpdateData = (actualData: GroupedData, toMatchWIth: GroupedData) => {
  const nullDataObj = {
    yearType: '',
    CARBON_BUDGET: null,
    CARBON_EMISSION: null,
    QUANTITY: null,
    SOURCE: '',
    TRADE_COMMODITY_NAME: '',
    TRADE_YEAR: 0,
    type: '',
    year: 0,
  };
  toMatchWIth.data.forEach((data, index) => {
    const isNameMatching = actualData.data.some(
      (actualObj) => actualObj.yearType === data.yearType,
    );
    if (!isNameMatching) {
      actualData.data.splice(index, 0, {
        ...nullDataObj,
        yearType: data.yearType,
        TRADE_YEAR: data.TRADE_YEAR,
        type: data.type,
        year: data.year,
      });
    }
  });
  return actualData;
};
export const getIconForSeries = (dashStyle: string, name: string): string => {
  let icon = '';
  if (dashStyle === 'Solid' && name === 'NCI Range') {
    icon = '\u25A0';
  } else if (dashStyle === 'Solid') {
    icon = '\u25AC';
  } else if (dashStyle === 'Dash') {
    icon = `\u254c`;
  } else {
    icon = '\u25AC';
  }
  return `<span style="color:{series.color}">${icon}</span>`; // Placeholder
};

export const toolTipFormatter = (pointsData: PointsData, isReverseTooltip?: boolean) => {
  const { points } = pointsData;
  const tooltipPoints = isReverseTooltip ? points.slice().reverse() : points;
  let tooltip = `<span style="font-size: 12px">${pointsData.x}</span><br/>`;
  tooltipPoints.forEach(({ color, series, point, y, x }) => {
    const low = point?.low;
    const high = point?.high;
    if (typeof color === 'string' && series && point && y) {
      let tooltipText = '';
      if (Number(x) < currentYear) {
        tooltipText = '(History)';
      }
      const dashStyle = series.userOptions.dashStyle;
      const getToolTipIco = getIconForSeries(dashStyle, series.name);
      if (low && high) {
        tooltip += `</br/><span style="color:${color}; font-size: 20px">${getToolTipIco}</span> Maximum NCI Target ${tooltipText}: <b>${Highcharts.numberFormat(
          high ?? 0,
          two,
        )}</b></br><span style="color:${color}; font-size: 20px">${getToolTipIco}</span> Minimum NCI Target ${tooltipText}: <b>${Highcharts.numberFormat(
          low ?? 0,
          two,
        )}</b></br>`;
      } else {
        tooltip += `<span style="color:${color}">${getToolTipIco}</span> ${
          series.name
        } ${tooltipText}: <b>${Highcharts.numberFormat(y ?? 0, two)}</b></br>`;
      }
    }
  });
  return tooltip;
};

export const makeNumberFromDigits = (digits: number[]) => {
  return parseInt(digits.join(''), ten);
};

export const getColorByIndex = (index: number, commodityVal: string): string => {
  let colors: string[] = [];
  if (commodityVal === power) {
    colors = ['#ff887b', '#0097a9', '#326094'];
  } else if (commodityVal === pipeLineGas || commodityVal === naturalGas) {
    colors = ['#A0C963', '#008557'];
  } else if (commodityVal === renewableGas) {
    colors = ['#A0C963', '#008557'];
  } else {
    colors = ['#6fb993', '#ffc600', '#9a60a4', '#ff887b'];
  }
  return colors[index % colors.length];
};

export const getNciTileData = (committedData: PlannedVsCommitted[], nciData: IGroupNCI[]) => {
  // Filter committed data by source and current year
  const filterDataBySource = committedData.filter(
    (item) =>
      [TANSO_FUTURE, REPORTONE, POWER_INTENSITY_MODEL_RES].includes(item.SOURCE) &&
      item.TRADE_YEAR === currentYear,
  );
  const currentYearNci = nciData.filter((item) => item.YEAR === currentYear)[0];
  const totals = filterDataBySource.reduce(
    (acc, item) => {
      acc.carbonEmission += item.CARBON_EMISSION;
      acc.volume += item.QUANTITY;
      return acc;
    },
    { carbonEmission: 0, volume: 0 },
  );

  // Convert values to desired units
  const carbonEmissionInGco2e = convertValue(totals.carbonEmission, 'mtpa', 'gco2e') ?? 0;
  const volumeInMj = convertValue(totals.volume, 'twh', 'mj') ?? 0;
  const nci = carbonEmissionInGco2e / volumeInMj; // in gco2e/mj
  if (isNaN(nci)) {
    return { netCarbonIntensity: 0, lowerNciTarget: 0, higherNciTarget: 0, unit: '' }; // or handle the error as appropriate
  }
  return {
    netCarbonIntensity: nci,
    lowerNciTarget: currentYearNci.GROUP_NCI_TARGET_HIGHER,
    higherNciTarget: currentYearNci.GROUP_NCI_TARGET_LOWER,
    unit: 'gCO2e/MJ',
  };
};

export const getSubTitle = (commodityVal: string, chartType: string, title: string) => {
  if (commodityVal === power && chartType === 'sales') {
    return 'Renewable, Non-Renewable and Residual Grid sales volumes.';
  } else if (commodityVal === pipeLineGas && chartType === 'sales') {
    return 'Renewable and Pipeline Gas Sales Volumes.';
  } else {
    return `Carbon emission of ${title} Sales.`;
  }
};

export const transformedPlanData = (planData: PlannedVsCommitted[]) => {
  const summedCarbonEmissions = planData.reduce((acc, curr) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const { TRADE_YEAR, CARBON_EMISSION, QUANTITY, EVP, ...rest } = curr;
    if (TRADE_YEAR in acc) {
      acc[TRADE_YEAR].CARBON_EMISSION += CARBON_EMISSION;
      acc[TRADE_YEAR].QUANTITY += QUANTITY;
    } else {
      acc[TRADE_YEAR] = { TRADE_YEAR, CARBON_EMISSION, QUANTITY, ...rest };
    }
    return acc;
  }, {} as { [key: number]: Omit<PlannedVsCommitted, 'EVP'> });
  return Object.values(summedCarbonEmissions);
};

export const getContractedPlanLeFiltersData = (
  seriesData: PlannedVsCommitted[],
  selectedCommodity: string,
  selectedEvp: string,
) => {
  return seriesData.filter((series) => {
    const matchCommodity =
      selectedCommodity === 'ALL' || series.TRADE_COMMODITY_NAME.includes(selectedCommodity);
    const matchEvp = selectedEvp === 'ALL' || series.EVP === selectedEvp;
    return matchCommodity && matchEvp;
  });
};

export const getCommodityAndEvpOptions = (seriesData: PlannedVsCommitted[]) => {
  const uniqueCommodities = [
    ...new Set(
      seriesData.map((series) => series.TRADE_COMMODITY_NAME?.replace('(Deal)', '').trim()),
    ),
  ];
  const uniqueEvp = [...new Set(seriesData.map((series) => series.EVP))];
  return { uniqueCommodities, uniqueEvp };
};
export const getQuarterByMonth = () => {
  const currentMonth = new Date().getMonth() + one;
  return quarters.find((q) => q.months.includes(currentMonth))?.quarter;
};
export const getCalculatedQuaterData = (planData: number) => {
  const currentQuarter = getQuarterByMonth() as number;
  return (planData * (currentQuarter - one)) / four;
};
export const updatedCurrentYearHistoricalData = (mergedData: DataEntry[]) => {
  const historicalKey = `${currentYear}-historical`;
  const planKey = `${currentYear}-plan`;
  return mergedData.reduce((acc: DataEntry[], historicalItem) => {
    if (historicalItem.yearType === historicalKey) {
      const planItem = acc.find(
        (item) =>
          item.yearType === planKey &&
          item.TRADE_COMMODITY_NAME === historicalItem.TRADE_COMMODITY_NAME,
      );
      if (
        planItem?.CARBON_EMISSION &&
        historicalItem.CARBON_EMISSION &&
        planItem.QUANTITY &&
        historicalItem.QUANTITY
      ) {
        planItem.CARBON_EMISSION =
          historicalItem.CARBON_EMISSION + getCalculatedQuaterData(planItem.CARBON_EMISSION);
        planItem.QUANTITY = historicalItem.QUANTITY + getCalculatedQuaterData(planItem.QUANTITY);
      }
    } else {
      acc.push(historicalItem);
    }
    return acc;
  }, []);
};

export const updateCommodityData = (entries: IYearDataRegion[]): IYearDataRegion[] => {
  const historicalKey = `${currentYear}-historical`;
  const planKey = `${currentYear}-plan`;
  return entries.reduce((acc: IYearDataRegion[], entry) => {
    const historicalEntry = entries.find((hEntry) => hEntry.yearType === historicalKey);
    const planEntry = entries.find((pEntry) => pEntry.yearType === planKey);
    if (entry?.yearType === historicalKey) {
      if (historicalEntry && planEntry) {
        planEntry.CARBON_EMISSION =
          historicalEntry.CARBON_EMISSION + getCalculatedQuaterData(planEntry.CARBON_EMISSION);
        planEntry.QUANTITY = historicalEntry.QUANTITY + getCalculatedQuaterData(planEntry.QUANTITY);
      }
    } else {
      acc.push(entry);
    }
    return acc;
  }, []);
};

export const updateSplitDataWithRegion = (data: RegionDataObject) => {
  return Object.entries(data).reduce((acc, [region, commodities]) => {
    acc[region] = Object.entries(commodities).reduce((commodityAcc, [commodity, entries]) => {
      commodityAcc[commodity] = updateCommodityData(entries);
      return commodityAcc;
    }, {} as { [commodity: string]: IYearDataRegion[] });
    return acc;
  }, {} as RegionDataObject);
};

export const mergeActualAndPlanCommodity = (
  processedCombinedArrayWithKey: {
    name: string;
    data: ProcessedDataItem[];
  }[],
) => {
  const actualsKey = `${currentYear}-actuals`;
  const planKey = `${currentYear}-plan`;
  processedCombinedArrayWithKey.forEach((item) => {
    const actualEntry = item.data.find((entry) => entry.yearType === actualsKey);
    const planEntry = item.data.find((entry) => entry.yearType === planKey);

    if (actualEntry && planEntry) {
      planEntry.value = actualEntry.value + getCalculatedQuaterData(planEntry.value);
    }
    item.data = item.data.filter((entry) => entry.yearType !== actualsKey);
  });
  return processedCombinedArrayWithKey;
};
export const mergeActualAndPlanCarbonIntensity = (
  processedCombinedArrayWithKey: {
    name: string;
    data: GroupedDataItem[] | TargetDataItem[];
  }[],
) => {
  const actualsKey = `${currentYear}-actuals`;
  const planKey = `${currentYear}-plan`;
  processedCombinedArrayWithKey.forEach((item) => {
    const actualEntry = item.data.find((entry) => `${entry.year}-${entry.type}` === actualsKey);
    const planEntry = item.data.find((entry) => `${entry.year}-${entry.type}` === planKey);

    if (actualEntry && planEntry && actualEntry.total_value && planEntry.total_value) {
      const averageValue = (actualEntry.total_value + planEntry.total_value) / two;
      planEntry.total_value = averageValue;
    }
    if (item.name.includes('Target Region')) {
      const currentYearEntryIndex = item.data.findIndex(
        (entry) => entry.year === currentYear.toString(),
      );
      if (currentYearEntryIndex !== -one) {
        item.data.splice(currentYearEntryIndex, one);
      }
    } else {
      item.data = item.data.filter((entry) => `${entry.year}-${entry.type}` !== actualsKey);
    }
  });
  return processedCombinedArrayWithKey;
};
