/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Footer from '../Footer/Footer';
import { Divider } from '@sede-x/shell-ds-react-framework';
import './datavisualization.css';
import AverageMarginToCarbonRatio from '../AverageMargintoCarbonRatio/AverageMargintoCarbonRatio';
import CarbonDashBoard from '../CarbonDashBoard/CarbonDashBoard';
import Loader from '../Loader/Loader';
import { IUserInfo } from '../../Types/types';
import { DataVisualizationContainer, OverAllContainer } from './DataVisulization.styles';
import OverallPortfolioChart from '../charts/OverallPortfolioChart';
import { fetchCarbonBudgetLine, fetchCarbonemissionDeltaView } from '../../api';
import LatestEstimateVsPlanned from '../charts/ContractedVsPlanVsLe';
import StackWaterfall from '../charts/StackWaterfall';
import { calculateEmissionsByTradeCommodityName, makeNumberFromDigits } from '../../utils';
import { notify } from '../Toast/notify';
import { FETCH_ERROR } from '../../utils/constants';
import { one, two, zero } from '../../constants';

interface DataVisualizationProps {
  user: IUserInfo;
}

const DataVisualization: React.FC<DataVisualizationProps> = ({ user }) => {
  const [carbonBudgetLineData, setCarbonBudgetLineData] = useState([]);
  const currentDate = new Date();
  const historicalStartYear = currentDate.getFullYear() - two;
  const planningEndYear = currentDate.getFullYear() + makeNumberFromDigits([one, zero]);

  const fetchCarbonBudget = async () => {
    const carbonbudgetline = await fetchCarbonBudgetLine();
    const carbonbudgetFilterLine = carbonbudgetline.data.filter(
      (x: { TRADE_YEAR: number }) =>
        x.TRADE_YEAR >= historicalStartYear && x.TRADE_YEAR < planningEndYear,
    );
    setCarbonBudgetLineData(carbonbudgetFilterLine);
  };

  type CommodityTableRow = {
    TRADE_COMMODITY_NAME: string;
    latest_estimate: number;
    plan: number;
    delta: number;
  };

  const initialCommodityTableRows: CommodityTableRow[] = [
    { TRADE_COMMODITY_NAME: 'Hydrogen', latest_estimate: 0, plan: 0, delta: 0 },
    { TRADE_COMMODITY_NAME: 'Natural Gas', latest_estimate: 0, plan: 0, delta: 0 },
    { TRADE_COMMODITY_NAME: 'Power', latest_estimate: 0, plan: 0, delta: 0 },
    { TRADE_COMMODITY_NAME: 'LNG', latest_estimate: 0, plan: 0, delta: 0 },
  ];
  const [joinedSources, setJoinedSources] = useState('');
  const [commodityRowsData, setCommodityRowsData] =
    useState<CommodityTableRow[]>(initialCommodityTableRows);
  const fetchAllData = async () => {
    try {
      const carbonEmissionData = await fetchCarbonemissionDeltaView();
      const uniqueSources =
        carbonEmissionData &&
        Array.from(new Set(carbonEmissionData.data.map((item: { SOURCE: string }) => item.SOURCE)));
      setJoinedSources(uniqueSources.join(', '));
      const groupCommodityData =
        carbonEmissionData && calculateEmissionsByTradeCommodityName(carbonEmissionData.data);
      groupCommodityData && setCommodityRowsData(groupCommodityData);
    } catch {
      notify('error', FETCH_ERROR);
    }
  };

  useEffect(() => {
    fetchAllData();
    fetchCarbonBudget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='DataVisual' data-testid='datavisual'>
        <CarbonDashBoard user={user} />
        <Divider size='medium' direction='horizontal' />
        <Loader />
        <DataVisualizationContainer>
          <OverAllContainer>
            <OverallPortfolioChart carbonBudgetLineData={carbonBudgetLineData} />
          </OverAllContainer>
          <OverAllContainer>
            <LatestEstimateVsPlanned />
          </OverAllContainer>
        </DataVisualizationContainer>
        <DataVisualizationContainer>
          <OverAllContainer>
            <StackWaterfall commodityRowsData={commodityRowsData} uniqueSource={joinedSources} />
          </OverAllContainer>
          <OverAllContainer>
            <AverageMarginToCarbonRatio />
          </OverAllContainer>
        </DataVisualizationContainer>
      </div>
      <Footer />
    </>
  );
};

export default DataVisualization;
