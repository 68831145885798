import React, { useEffect, useContext, useState } from 'react';
import { ChartContainer, TableHeading } from './RegionVolumeDetailsTable.style';
import { getSnapshotRegionVolumeDetailsTableData } from '../../../../../../api/services';
import { BaseTable, Flexbox } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import { useLocation } from 'react-router-dom';
import Loader from '../../../../../Loader/Loader';
import { RegionVolumeDetailsTableData } from '../../../../../../Types/types';

const RegionVolumeDetailsTable: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const region = queryParams.get('region');
  const parameterName = queryParams.get('parameterName');
  const { state: analyticsState, dispatch: snapshotDispatch } =
    useContext(AppContext).analyticsSnapshotReport;

  const mapData: RegionVolumeDetailsTableData[] = analyticsState.volumeByRegionDetailsTableData;
  const sum = mapData
    ?.reduce((acc: number, item: RegionVolumeDetailsTableData) => acc + item.QTY, 0)
    ?.toFixed(2);
  const tableFormattedData = [...mapData].map((el) => {
    return {
      ...el,
      QTY: Math.round(Number(el.QTY) * 100) / 100,
    };
  });

  const columnNames = [
    'YEAR',
    'QUARTER',
    'MONTH',
    'COMMODITY',
    'TRANSACTION_TYPE',
    'ORIGINAL_TRANSACTION_TYPE',
    'NCF_CATEGORY',
    'CPTY',
    'CPTY_DESC',
    'QTY',
  ];
  const columns = columnNames.map((key) => ({
    header: key.replace(/_/g, ' '),
    accessorKey: key,
  }));

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let response;
      try {
        response = await getSnapshotRegionVolumeDetailsTableData({
          ...analyticsState.analyticsSnapshotReportFilteredValues,
          region: [region],
          parameterName,
        });
        // Use the snapshotDispatch function to call setNcfQuarterwise
        snapshotDispatch({
          type: 'setRegionVolumeDetailsTableData',
          payload: response.data,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        // Log error once toast feature is implemented
        snapshotDispatch({
          type: 'setError',
          payload: {
            isError: true,
            message: 'Failed to fetch data. Please try again.',
          },
        });
      }
    };

    fetchData();
  }, [
    snapshotDispatch,
    analyticsState.analyticsSnapshotReportFilteredValues,
    region,
    parameterName,
  ]); // Add snapshotDispatch to the dependency array

  return (
    <ChartContainer>
      <Loader loader={isLoading} message={''} />
      <Flexbox style={{ width: '100%' }} justifyContent='space-between'>
        <TableHeading>Detail View of {parameterName}</TableHeading>
      </Flexbox>
      <BaseTable
        columns={columns}
        data={tableFormattedData}
        size='large'
        data-testid='base-table'
        style={{ width: '100%' }}
        stickyHeader={true}
        maxHeight={500}
      />
      Total {sum}
    </ChartContainer>
  );
};

export default RegionVolumeDetailsTable;
