import React, { useEffect } from 'react';
import { Flexbox, Grid } from '@sede-x/shell-ds-react-framework';
import Footer from '../Footer/Footer';
import './commodityOverview.css';
import { fetchCommodityOverview, fetchCarbonIntensityTarget } from '../../api/services';
import Loader from '../Loader/Loader';
import { SE_GLOBAL_SBTI } from '../../api/constants';
import { BorderDiv } from './commodityOverview.style';
import FilterSection from './FilterSection';
import { filterTargetDataByRegion, getFilterOptions } from './Utils';
import { AllCharts } from './AllCharts';
import { resetState, setField } from '../../redux/commodityDetails/commodityDetailsSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/rootState';
import { hideSpinner, showSpinner } from '../../redux/datavisualization/datavisualizationSlice';
import { TradeData } from '../../Types/types';
import { notify } from '../Toast/notify';
import { FETCH_ERROR } from '../../utils/constants';
import { DasboardContainer } from '../CarbonDashBoard/CarbonDashBoard.styles';

const CommodityOverview = () => {
  const dispatch = useAppDispatch();
  const { commodityVal } = useAppSelector(
    (state: RootState) => state.commodityOverview, // Use the updated slice name
  );

  const fetchCommodityOverviewData = async () => {
    dispatch(showSpinner());
    try {
      const commodityoverview = await fetchCommodityOverview();
      const commodityOverviewApi = commodityoverview.data;
      const commodityOverview = commodityOverviewApi.filter(
        (x: { COUNTRY: string }) => x.COUNTRY !== null,
      );
      const commodityFiltered = commodityOverview.filter(
        (x: { TRADE_COMMODITY_NAME: string }) => x.TRADE_COMMODITY_NAME === commodityVal,
      );
      dispatch(
        setField({
          masterCommodityFilteredValue: commodityOverview,
          nonCommodityFilteredValue: commodityFiltered,
          commodityFilteredValue: commodityFiltered,
          sauData: commodityFiltered,
        }),
      );
      const sauOptions = getFilterOptions(commodityFiltered, 'SAU');
      const evpOptions = ['ALL', ...getFilterOptions(commodityFiltered, 'EVP')];
      const seIpuRegionOptions = ['ALL', ...getFilterOptions(commodityFiltered, 'SE_IPU_Region')];
      const businessOptions = [
        'ALL',
        ...getFilterOptions(commodityFiltered, 'REPORTING_ENTITY_NAME'),
      ];
      dispatch(
        setField({
          sauVal: sauOptions[0],
          sauId: sauOptions[0],
          sauOptions,
          evpVal: 'ALL',
          seIpuRegionVal: 'ALL',
          bizVal: 'ALL',
          evpOptions,
          seIpuRegionOptions,
          businessOptions,
        }),
      );

      const commodityOptions: TradeData[] = commodityOverview
        .map((val: TradeData) => val.TRADE_COMMODITY_NAME)
        .filter((v: TradeData, index: number, self: TradeData[]) => self.indexOf(v) === index);
      dispatch(setField({ key: 'commodityOptions', value: commodityOptions }));
      // Getting carbon intensity target values
      const carbonintensityTargetResp = await fetchCarbonIntensityTarget();
      const carbonintensityTargetData = carbonintensityTargetResp.data;
      dispatch(setField({ key: 'carbonIntensityTarget', value: carbonintensityTargetData }));

      const filterData = filterTargetDataByRegion(SE_GLOBAL_SBTI, carbonintensityTargetData);
      dispatch(setField({ key: 'carIntTargetPerRegion', value: filterData }));
    } catch {
      notify('error', FETCH_ERROR);
    }
    dispatch(hideSpinner());
  };

  useEffect(() => {
    fetchCommodityOverviewData();
    // Reset the state when the component is unmounted
    dispatch(resetState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loader />
      <Grid>
        <DasboardContainer>
          <Flexbox gap='24px 24px' style={{ marginLeft: '15px', justifyContent: 'start' }}>
            <FilterSection />
            <BorderDiv />
            <AllCharts />
          </Flexbox>
        </DasboardContainer>
      </Grid>
      <Footer />
    </>
  );
};

export default CommodityOverview;
