import React, { useEffect, useContext, useState } from 'react';
import { ChartContainer, TableHeading } from './TopCtpyTable.style';
import { allCounterParty } from '../../../../../../api/services';
import { BaseTable, Flexbox } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import Loader from '../../../../../Loader/Loader';

const AllCtpyTable: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { state: analyticsState, dispatch: analyticsDispatch } =
    useContext(AppContext).analyticsHome;

  const columnNames = [
    'CounterpartyName',
    'NcfCategory',
    'Country',
    'Year',
    'Quantity',
    'Percentage',
  ];

  const columns = columnNames.map((key) => ({
    header: key === 'Quantity' ? `Quantity (${analyticsState?.cptyFilteredValues?.uom})` : key,
    accessorKey: key,
  }));

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let response;
      try {
        const pagefilterValues = {
          ...analyticsState.cptyFilteredValues,
          region: ['AMERICAS'],
        };
        response = await allCounterParty(pagefilterValues);
        analyticsDispatch({
          type: 'setCounterpartyData',
          payload: response.data,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        // Log error once toast feature is implemented
      }
    };

    fetchData();
  }, [analyticsDispatch, analyticsState.cptyFilteredValues]);

  useEffect(() => {
    // All CPTy feature is only for AMERICAS region. So, update the region filter to AMERICAS if it is not already set.
    if (
      !analyticsState.cptyFilterOptions.region.includes('AMERICAS') ||
      analyticsState.cptyFilterOptions.region.length > 1
    ) {
      analyticsDispatch({
        type: 'setCptyFilterOptions',
        payload: {
          ...analyticsState.cptyFilterOptions,
          region: ['AMERICAS'],
        },
      });
    }
  }, [analyticsDispatch, analyticsState.cptyFilterOptions]);

  return (
    <ChartContainer>
      <Loader loader={isLoading} message={''} />
      <Flexbox style={{ width: '100%' }} justifyContent='space-between'>
        <TableHeading>All Counterparties Table</TableHeading>
      </Flexbox>
      <BaseTable
        columns={columns}
        data={analyticsState.counterpartyData}
        size='large'
        data-testid='base-table'
        stickyHeader={true}
        maxHeight={800}
      />
    </ChartContainer>
  );
};

export default AllCtpyTable;
